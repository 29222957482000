const BASE_URL = "/api";

export const RendezVousApi = {
    addRdv: async (rendezVous) => {
        try {
            const response = await fetch(`${BASE_URL}/planning/rdv/add`, {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(rendezVous),
            });

            if (!response.ok) {
                throw new Error("Erreur lors de l'ajout du rendez-vous");
            }
            return await response.json();
        } catch (error) {
            console.error("Erreur API:", error.message);
            return null;
        }
    },

    getRdvByDate: async (date) => {
        try {
            const response = await fetch(`${BASE_URL}/rendezvous?date=${encodeURIComponent(date)}`, {
                method: "GET",
                headers: { "Content-Type": "application/json" },
            });

            if (!response.ok) {
                throw new Error("Erreur lors de la récupération des rendez-vous");
            }
            return await response.json();
        } catch (error) {
            console.error("Erreur API:", error.message);
            return null;
        }
    },

    addConge: async (conge) => {
        try {
            const response = await fetch(`${BASE_URL}/planning/conges/add`, {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(conge),
            });

            if (!response.ok) {
                throw new Error("Erreur lors de l'ajout du congé");
            }
            return await response.json();
        } catch (error) {
            console.error("Erreur API:", error.message);
            return null;
        }
    },

    getJoursConges: async () => {
        try {
            const response = await fetch(`${BASE_URL}/planning/conges/getJoursConges`);
            if (!response.ok) {
                throw new Error("Erreur lors de la récupération des jours de congés.");
            }
            return response.json();
        } catch (error) {
            console.error("Erreur API:", error.message);
            return null;
        }
    },

    getPeriodesConges: async () => {
        try {
            const response = await fetch(`${BASE_URL}/planning/conges/getPeriodes`);
            if (!response.ok) {
                throw new Error("Erreur lors de la récupération des périodes de congés.");
            }
            return response.json();
        } catch (error) {
            console.error("Erreur API:", error.message);
            return null;
        }
    },

    getTakenSlots: async (date) => {
        try {
            const response = await fetch(`${BASE_URL}/planning/rdv/getTakenSlots`, {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ dateRdv: date }),
            });
    
    
            return response.json();
        } catch (error) {
            console.error("Erreur API:", error.message);
            return []; // Retourne un tableau vide en cas d'erreur
        }
    },
    
    cancelRdv: async (token) => {
        try {
            const response = await fetch(`${BASE_URL}/planning/rdv/cancel`, {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ token }),
            });

            if (!response.ok) {
                throw new Error("Erreur lors de l'annulation du rendez-vous.");
            }
            return response.json();
        } catch (error) {
            console.error("Erreur API:", error.message);
            return null;
        }
    },

    getRdvByUuid: async (token) => {
        try {
            const response = await fetch(`${BASE_URL}/planning/rdv/getByUuid`, {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ token }),
            });

            if (!response.ok) {
                throw new Error("Erreur lors de la récupération du rendez-vous.");
            }
            return response.json();
        } catch (error) {
            console.error("Erreur API:", error.message);
            return null;
        }
    },
};
