import "./home.css"
import { useNavigate } from 'react-router-dom'
import React from "react"
import imgMoi from "../../assets/moi2.png"
import imgHtml5 from "../../assets/logoHtml.png"
import imgCss3 from "../../assets/logoCss.png"
import imgBootstrap from "../../assets/logoBootstrap.png"
import imgJavascript from "../../assets/logoJs.png"
import imgPhp from "../../assets/logoPhp.png"
import imgReact from "../../assets/logoReact.png"
import imgJava from "../../assets/logoJava.png"
import imgMySql from "../../assets/logoMysql.png"
import imgAndroidStudio from "../../assets/logoAndroid.png"

import imgSurMesure from "../../assets/logoSurMesure.png"
import imgResponsive from "../../assets/imgResponsive.png"
import imgSiteSecure from "../../assets/imgSiteSecurise.png"
import imgSeo from "../../assets/imgSeo.png"
import imgNoWordpress from "../../assets/imgWordpress.png"
import imgStar from "../../assets/star.png"
import imgStarMoitie from "../../assets/starMoitie.png"
import imgStarVide from "../../assets/starVide.png"
import imgDev from "../../assets/imgDev.png"

import imgCarteSomme from "../../assets/carte-somme.png"
import imgCarteOise from "../../assets/carte-oise.png"
import imgCarteAisne from "../../assets/carte-aisne.png"
import imgCarteNord from "../../assets/carte-nord.png"
import imgCartePasDeCalais from "../../assets/carte-pas-de-calais.png"
 

function Home() {
    const navigate = useNavigate();

  const handleRedirect = () => {
    navigate('/contact'); // Redirige vers la page de contact
  };

    return (
        <main>  
            
                
                
            {/* Introduction Section */}
            <section id="presentation" className="section-container">
                <h1 className="section-title">Développement de sites web professionnels sur-mesure en Hauts-de-France.</h1>
                <p>
                Vous êtes situé dans les Hauts-de-France et vous cherchez un site internet à la fois moderne, performant et bien positionné sur Google ? Bonnard Web Editions conçoit des solutions sur-mesure pour les entrepreneurs et professionnels souhaitant développer leur présence en ligne. Grâce à un design soigné, un code optimisé et une attention particulière au référencement, chaque site est pensé pour attirer et convertir vos visiteurs. Faites le choix d’un accompagnement personnalisé et d’un développement sans CMS pour un site unique, rapide et évolutif, parfaitement adapté à vos besoins.
                </p>
                
                <button className="btn-grad" onClick={handleRedirect}>
                    CONTACTEZ-MOI
                </button>
                <button className="btn-grad" onClick={()=>{navigate("/rendezVous")}}>
                    PRENDRE RENDEZ-VOUS
                </button>
            </section>
            <section id="zone-intervention" className="section-zone-intervention">
                <h2 className="section-title">ZONE D'INTERVENTION</h2>
                <p>
                    Basé à <strong>Flixecourt</strong>, j'interviens dans toute la région <strong>Hauts-de-France</strong>, 
                    et plus particulièrement dans les départements de la <strong>Somme (80)</strong>, du <strong>Pas-de-Calais (62)</strong> et du <strong>Nord (59)</strong>.  
                    Que vous soyez à <strong>Amiens, Abbeville, Arras, Lille, Beauvais, Saint-Quentin</strong> ou ailleurs, je vous accompagne dans la création de votre site web.
                </p>

                <div className="intervention-grid">
        {[
            {
                img: imgCarteSomme, // Ajoute tes cartes des départements
                title: "Somme (80)",
                cities: "Amiens, Abbeville, Albert, Péronne, Doullens, Roye, Flixecourt"
            },
            {
                img: imgCarteOise,
                title: "Oise (60)",
                cities: "Beauvais, Compiègne, Creil, Senlis, Chantilly, Noyon"
            },
            {
                img: imgCarteAisne,
                title: "Aisne (02)",
                cities: "Saint-Quentin, Laon, Soissons, Château-Thierry"
            },
            {
                img: imgCartePasDeCalais,
                title: "Pas-de-Calais (62)",
                cities: "Arras, Lens, Béthune, Boulogne-sur-Mer, Calais, Saint-Omer"
            },
            {
                img: imgCarteNord,
                title: "Nord (59)",
                cities: "Lille, Roubaix, Tourcoing, Dunkerque, Valenciennes, Douai, Cambrai"
            }
        ].map((dept, index) => (
            <div className="intervention-card" key={index}>
                <h3>{dept.title}</h3>
                <img src={dept.img} alt={`Carte ${dept.title}`} className="map-image" />
                <p>{dept.cities}</p>
            </div>
        ))}
    </div>

                
                    
                    
                
                <button className="btn-grad" onClick={handleRedirect}>
                    CONTACTEZ-MOI
                </button>
                <button className="btn-grad" onClick={()=>{navigate("/rendezVous")}}>
                    PRENDRE RENDEZ-VOUS
                </button>
            </section>

            <section className="quiSuisJe">
                <h2 style={{ textAlign: "center" }}>
                    QUI SUIS-JE ? <span style={{ display: "none" }}>Bonnard Germain</span>
                </h2>
                <div className="container-photo" id="maPhoto" >
              <img
                id="photoPerso"
                src={imgMoi}
                alt="moi"
              />
            </div>
            <div className="presentationQuiSuisJe">
                <p>
                    Bonnard Germain, Concepteur / Développeur web et logiciel passionné de Flixecourt dans les Hauts-de-France, diplômé Bac+4 en développement web, avec plus de 10 ans d'expérience.
                    Je réalise des sites performants, sécurisés et optimisés pour le référencement naturel (SEO).
                </p>
            </div>
            <div className="mesCompetences">
                <h2 style={{textAlign:"center"}}>MES COMPÉTENCES</h2>
                <div className="competences-grid">
                    {[
                        {
                            img: <img src={imgHtml5} alt="logo Html 5" />,
                            title:"HTML 5",
                            note:5
                        },
                        {
                            img: <img src={imgCss3} alt="logo css" />,
                            title:"CSS 3",
                            note:5
                        },
                        {
                            img: <img src={imgJavascript} alt="logo javascript" />,
                            title:"JAVASCRIPT",
                            note:5
                        },
                        {
                            img: <img src={imgBootstrap} alt="logo bootstrap" />,
                            title:"BOOTSTRAP 4",
                            note:5
                        },
                        
                        {
                            img: <img src={imgPhp} alt="logo php" />,
                            title:"PHP 8",
                            note:5
                        },
                        {
                            img: <img src={imgReact} alt="logo react" />,
                            title:"REACT",
                            note:5
                        },
                        
                        {
                            img: <img src={imgMySql} alt="logo javascript" />,
                            title:"MYSQL",
                            note:5
                        },
                        {
                            img: <img src={imgJava} alt="logo java" />,
                            title:"JAVA",
                            note:5
                        },
                        {
                            img: <img src={imgAndroidStudio} alt="logo android studio" />,
                            title:"JAVA",
                            note:5
                        },
                    ].map((competence,index) =>(
                        <div className="competence-card" key={index}>
                            {competence.img}
                            <div className="stars">
                                {[...Array(5)].map((_, i) => {
                                    if (competence.note >= i + 1) {
                                        return <img key={i} src={imgStar} alt="étoile pleine" />;
                                    } else if (competence.note > i && competence.note < i + 1) {
                                        return <img key={i} src={imgStarMoitie} alt="demi-étoile" />;
                                    } else {
                                        return <img key={i} src={imgStarVide} alt="étoile vide" />;
                                    }
                                })}
                            </div>
                        </div>
                    ))
                    }
                    </div>
            </div>
            <button className="btn-grad" onClick={handleRedirect}>
                    CONTACTEZ-MOI
                </button>
                <button className="btn-grad" onClick={()=>{navigate("/rendezVous")}}>
                    PRENDRE RENDEZ-VOUS
                </button>
      
            </section>

            {/* Benefits Section */}
            <section className="benefits-section">
    <h2 className="section-title">POURQUOI CHOISIR MES SERVICES ?</h2>
    <div className="benefits-grid">
        
            {[{img: <img src={imgSurMesure} alt="logo sur mesure"/>, title: "Un site 100% sur-mesure", text: "Design unique adapté à votre image."},
            {img: <img src={imgResponsive} alt="logo responsive"/>, title: "Optimisation mobile", text: "Compatibilité totale sur tous les écrans."},
            {img: <img src={imgSiteSecure} alt="logo site secure"/>, title: "Sécurisé et fiable", text: "Protection avancée des données."},
            {img: <img src={imgSeo} alt="logo seo"/>, title: "SEO performant", text: "Visibilité accrue sur Google."},
            {img: <img src={imgNoWordpress} alt="logo no wordpress"/>, title: "Sans limites techniques", text: "Développement sur-mesure sans WordPress."},
            {
                img: <img src={imgDev} alt="logo dev" />,
                title: "Un vrai développeur passionné à votre service",
                text: "Concepteur web passionné, diplomé et experimenté, je mets mes compétences techniques à votre disposition.",
            }
        ].map((benefit, index) => (
            <div className="benefit-card" key={index}>
                {benefit.img}
                <h3>{benefit.title}</h3>
                <p>{benefit.text}</p>
            </div>
        ))}
    </div>
    <button className="btn-grad" onClick={handleRedirect}>
        CONTACTEZ-MOI
    </button>
    <button className="btn-grad" onClick={()=>{navigate("/rendezVous")}}>
        PRENDRE RENDEZ-VOUS
    </button>
</section>


                
            <section className="commentCaMarche">
                <h2>COMMENT ÇA MARCHE ?</h2>
                <p>
                    <strong>1. Prise de contact :</strong> Prenez rendez-vous en ligne pour échanger sur votre projet.
                </p>
                <p>
                    <strong>2. Élaboration du projet :</strong> Lors du rendez-vous, je vous contacte pour définir ensemble les fonctionnalités et le design de votre site. Un cahier des charges détaillé est rédigé afin de structurer précisément votre projet.
                </p>
                <p>
                    <strong>3. Maquettage :</strong> Avant le développement, une maquette visuelle de votre site est réalisée pour valider son apparence et son ergonomie.
                </p>
                <p>
                    <strong>4. Développement :</strong> Je crée votre site sur-mesure en respectant vos attentes et en intégrant les meilleures pratiques.
                </p>
                <p>
                    <strong>5. Mise en ligne et suivi :</strong> Votre site est hébergé, sécurisé et optimisé pour le référencement. Un suivi est assuré pour garantir son bon fonctionnement.
                </p>
                <button className="btn-grad" onClick={handleRedirect}>
                    CONTACTEZ-MOI
                </button>
                <button className="btn-grad" onClick={()=>{navigate("/rendezVous")}}>
                    PRENDRE RENDEZ-VOUS
                </button>
    
</section>
            
        </main>
    )
}

export default Home
