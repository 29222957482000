import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import imgStartCall from "../../assets/startCall.png";
import imgStopCall from "../../assets/stopCall.png";
import "./styles.css";
import GestionSouscription from "../GestionSouscription";
import { useAuth } from "../../context/AuthContext";
import { startAppel, finishAppel, addAppel, resetAppel } from "../../redux/actions/appelActions";
import StarRating from "../StarRating";
import { clearSelectedProspect } from "../../redux/actions/prospectActions";
import GestionProspect from "../GestionProspect";
import GestionRappel from "../GestionRappel";
import { clearSelectedRappel, fetchRappels } from "../../redux/actions/rappelActions";
import { fetchStatsAppels } from "../../redux/actions/statsActions";
const GestionAppelProspect = () => {
    const dispatch = useDispatch();

    const selectedProspect = useSelector((state) => state.prospect.selectedProspect);
    const { selectedRappel } = useSelector((state) => state.rappel);

    const { userConnected } = useAuth();
    const initialData = useMemo(() => ({
        contactAppel: "",
        noteAppel: 0,
        isSiteExist: null,
        isProspectOk: false,
        isProspectNotResponding: null,
        bilanAppel: "",
    }), []);

    const [callDuration, setCallDuration] = useState(0); // ⏱ Stocker la durée de l'appel en secondes
    const [, setTimer] = useState(null); // ⏲️ Stocker l'ID du setInterval

    


    
    const {appel} = useSelector((state) => state.appel);
    const [formData,setFormData] = useState(initialData);
    useEffect(() => {
        if (appel.isAppelStarted && !appel.isAppelFinished) {
            setCallDuration(0); // Réinitialise la durée à 0 quand un appel démarre
            const interval = setInterval(() => {
                setCallDuration((prev) => prev + 1);
            }, 1000);
            setTimer(interval);
    
            // Nettoyer l'intervalle lorsque l'appel est terminé
            return () => clearInterval(interval);
        }
    }, [appel.isAppelStarted, appel.isAppelFinished]);
    // Convertir les secondes en format hh:mm:ss
    const formatTime = (seconds) => {
        const h = Math.floor(seconds / 3600).toString().padStart(2, "0");
        const m = Math.floor((seconds % 3600) / 60).toString().padStart(2, "0");
        const s = (seconds % 60).toString().padStart(2, "0");
        return `${h}:${m}:${s}`;
    };
    const [showFormSouscription,setShowFormSouscription] = useState(false);
    const [showFormUpdateProspect,setShowFormUpdateProspect] = useState(false);
    const [showFormRappel,setShowFormRappel] = useState(false);
    //, $_POST['contactAppel'], $_POST['isSiteExistant'], $_POST['noteAppel'], $_POST['isProspectOk'], $_POST['isProspectNotResponding'], $_POST['bilanAppel']))
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        let newValue = value;
    
        if (value === "true") {
            newValue = true;
        } else if (value === "false") {
            newValue = false;
        } else if (value === "") {
            newValue = null;
        }
    
        setFormData({
            ...formData,
            [name]: newValue,
        });
    };
    const handleSubmitAppel = useCallback((e) => {
        e.preventDefault();
        const data = new FormData();
        data.append("idProspect", appel.idProspect);
        data.append("dateAppelProspect", appel.dateAppel);
        data.append("heureDebutAppel", appel.heureDebutAppel);
        data.append("heureFinAppel", appel.heureFinAppel);
        data.append("contactAppel", formData.contactAppel);
        data.append("isSiteExistant", formData.isSiteExist);
        data.append("noteAppel", formData.noteAppel);
        data.append("isProspectOk", formData.isProspectOk);
        data.append("bilanAppel", formData.bilanAppel);
        data.append("isProspectNotResponding", formData.isProspectNotResponding);
        data.append("idRappel",selectedRappel?selectedRappel.idRappel:null);
        dispatch(addAppel(data));
        dispatch(resetAppel());
        dispatch(clearSelectedProspect());
        dispatch(clearSelectedRappel());
        const today = new Date().toISOString().split('T')[0]; // Format YYYY-MM-DD
        dispatch(fetchStatsAppels(today));
        dispatch(fetchRappels());
        setFormData(initialData);
    }, [appel, formData, dispatch,initialData,selectedRappel]); // Dependencies
    
    useEffect(() => {
        if (formData.isProspectNotResponding === true) {
            dispatch(finishAppel()); // Arrête l'appel
            
        }
    }, [formData.isProspectNotResponding,dispatch,handleSubmitAppel]);

    useEffect(() => {
        if (appel.isAppelFinished && formData.isProspectNotResponding) {
            handleSubmitAppel(new Event("submit")); // Simule un événement de soumission
        }
    }, [appel.isAppelFinished,formData.isProspectNotResponding,handleSubmitAppel]); // Se déclenche uniquement quand isAppelFinished change
    const handleStartCall = () => {
        
        dispatch(startAppel(selectedProspect.idProspect, userConnected.idUser));
    };
    
    const handleNotResponding = (e)=>{
        e.preventDefault();
        setFormData((prev) => ({ ...prev, isProspectNotResponding: true }));
       
    }
    const handleRatingSubmit = (note) => {
        setFormData((prev) => ({ ...prev, noteAppel: note }));
    };
    
    return(
        <>
            {
                selectedProspect&&(
                    <>
                    <div className="main-content-call"> 
                        {
                            showFormUpdateProspect&&(
                                <GestionProspect onClose={()=>{setShowFormUpdateProspect(false)}} />
                            )
                        }
                        {
                            showFormRappel&&(
                                <GestionRappel onClose={()=>{setShowFormRappel(false)}}/>
                            )
                        }
                        {
                            appel.isAppelStarted&&showFormSouscription&&(
                                
                                <GestionSouscription onClose={()=>{setShowFormSouscription(false)}}/>
                                
                            )

                        }
                        {appel.isAppelStarted && (
                            <div className="call-timer">⏳ Durée de l'appel : {formatTime(callDuration)}</div>
                        )}

                        
                        {
                            appel.isAppelStarted&&!showFormSouscription&&!formData.isProspectNotResponding&&formData.isProspectNotResponding!==null&&(
                                <div className="form-appel">
                                    <div className="input-form">
                                        <label htmlFor="contactAppel">Nom prénom contact</label>
                                        <input type="text" name="contactAppel" id="contactAppel" value={formData.contactAppel} onChange={handleInputChange} />
                                    </div>
                                    <div className="input-form">
                                        <label htmlFor="noteAppel">Evaluation de l'appel</label>
                                        <StarRating onRatingSubmit={handleRatingSubmit} ratingProp={formData.noteAppel}/>

                                    </div>
                                    <div className="input-form">
                                        <label htmlFor="isProspectOk">Rendez vous obtenu ?</label>
                                        <select type="text" name="isProspectOk" id="isProspectOk" value={formData.isProspectOk} onChange={handleInputChange} >
                                            <option value="true">oui</option>
                                            <option value="false">non</option>
                                        </select>
                                        
                                    </div>
                                    <div className="input-form">
                                        <label htmlFor="isSiteExist">Site existant ?</label>
                                        <select type="text" name="isSiteExist" id="isSiteExist" value={formData.isSiteExist} onChange={handleInputChange}>
                                            <option value="">Je ne sais pas</option>
                                            <option value="true">oui</option>
                                            <option value="false">non</option>
                                        </select>
                                        
                                    </div>
                                    <div className="input-form">
                                        <label htmlFor="bilanAppel">Bilan de l'appel :</label>
                                        <textarea
                                            id="bilanAppel"
                                            name="bilanAppel"
                                            value={formData.bilanAppel}
                                            onChange={handleInputChange}
                                            rows="5"
                                            placeholder="Comment s'est passé l'appel"
                                        />
                                    </div>
                                    {
                                        appel.isAppelFinished&&(
                                            <button onClick={handleSubmitAppel}>ENREGISTRER</button>
                                        )
                                            
                                        
                                    }
                                    
                                </div>
                            )
                        }
                        
                        
                            
                    </div>
                    <div className="controls-call">
                    {
                            !showFormUpdateProspect&&(
                                <button className="btn-grad" onClick={()=>{setShowFormUpdateProspect(true)}}>
                                    Mettre à jour le prospect
                                </button>
                            )
                        }
                        {
                            appel.isAppelStarted&&!showFormRappel&&(
                                <button className="btn-grad" onClick={()=>{setShowFormRappel(true)}}>
                                    AJOUTER UN RAPPEL
                                </button>
                            )
                        }
                    </div>
                    <div className="controls-call">
                        

                        {
                            !appel.isAppelStarted&&!appel.isAppelFinished&&(
                                <img src={imgStartCall} alt="icone debut appel" onClick={handleStartCall}/>

                            )
                        }
                        {
                            appel.isAppelStarted&&!appel.isAppelFinished&&!formData.isProspectNotResponding&&formData.isProspectNotResponding!==null&&(
                                <img src={imgStopCall} alt="icone fin appel" onClick={()=>{dispatch(finishAppel())}} />

                            )
                        }
                        {

                        }
                        {
                            appel.isAppelStarted&&!appel.isAppelFinished&&formData.isProspectNotResponding===null&&(
                                <>
                                    <button className="btn-grad" onClick={()=>{        
                                        setFormData((prev) => ({ ...prev, isProspectNotResponding: false }));
                                                            
                                    }}>
                                            ON DECROCHE
                                    </button>
                                    <button className="btn-grad" onClick={handleNotResponding}>PAS DE REPONSE</button>
                                </>
                                
                            )
                        }
                    </div>
                    </>
                )
            }
        </>
    )
}
export default GestionAppelProspect