const BASE_URL = "/api/services";

export const ServicesApi = {
    addService: async (service) => {
        try {
            const response = await fetch(`${BASE_URL}/add`, {
                method: "POST",
                body: service, // FormData est directement envoyé ici
            });

            if (!response.ok) {
                throw new Error("Erreur lors de l'ajout du service");
            }
            return await response.json();
        } catch (error) {
            console.error("Erreur API:", error.message);
            return null;
        }
    },
    getAllServices: async () => {
        try {
            const response = await fetch(`${BASE_URL}/get`);
            if (!response.ok) {
                throw new Error("Erreur lors de la récupération des services.");
            }
            return response.json();
        } catch (error) {
            console.error("Erreur API:", error.message);
            return null;
        }
    },
    updateService: async (updatedService) => {
        try {
            const response = await fetch(`${BASE_URL}/update`, {
                method: "PUT",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(updatedService),
            });

            if (!response.ok) {
                throw new Error("Erreur lors de la mise à jour du service");
            }
            return await response.json();
        } catch (error) {
            console.error("Erreur API:", error.message);
            return null;
        }
    },
    deleteService: async (idService) => {
        try {
            const response = await fetch(`${BASE_URL}/delete`, {
                method: "POST",
                body: JSON.stringify({idService}), // FormData est directement envoyé ici
            });

            if (!response.ok) {
                throw new Error("Erreur lors de la suppression du service");
            }
            return await response.json();
        } catch (error) {
            console.error("Erreur API:", error.message);
            return null;
        }
    },


}