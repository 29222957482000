import { AppelApi } from "../../services/AppelApi";

export const START_APPEL = "START_APPEL";
export const FINISH_APPEL = "FINISH_APPEL";
export const UPDATE_APPEL_DATA = "UPDATE_APPEL_DATA";
export const RESET_APPEL = "RESET_APPEL";
export const ADD_APPEL_REQUEST = "ADD_APPEL_REQUEST";
export const ADD_APPEL_SUCCESS = "ADD_APPEL_SUCCESS";
export const ADD_APPEL_FAILURE = "ADD_APPEL_FAILURE";
export const FETCH_APPELS_REQUEST = "FETCH_APPELS_REQUEST";
export const FETCH_APPELS_SUCCESS = "FETCH_APPELS_SUCCESS";
export const FETCH_APPELS_FAILURE = "FETCH_APPELS_FAILURE";


const getCurrentTime = () => {
    const currentTime = new Date();
    return currentTime.toLocaleTimeString('fr-FR', { hour12: false }); // Format 24 heures sans AM/PM
  };

  export const startAppel = (idProspect, idUser) => (dispatch) => {
    const dateAppel = new Date().toISOString().split("T")[0];


    dispatch({
        type: START_APPEL,
        payload: {
            idProspect,
            idUser,
            dateAppel:dateAppel,
            heureDebutAppel: getCurrentTime(),
        },
    });
};


export const finishAppel = () => (dispatch) => {

    dispatch({
        type: FINISH_APPEL,
        payload: {
            heureFinAppel: getCurrentTime(),
        },
    });
};

export const updateAppelData = (updatedData) => ({
    type: UPDATE_APPEL_DATA,
    payload: updatedData, // Un objet avec les champs à mettre à jour
});


export const resetAppel = () => ({
    type: RESET_APPEL,
});


export const addAppel = (appel) => async (dispatch) => {
    dispatch(addAppelRequest());
    try {
        await AppelApi.addAppel(appel);
        dispatch(addAppelSuccess());
    } catch (error) {
        dispatch(addAppelFailure(error.message));
    }
};
export const addAppelRequest = () => ({
    type: ADD_APPEL_REQUEST,
});

export const addAppelSuccess = () => ({
    type: ADD_APPEL_SUCCESS,
});

export const addAppelFailure = (error) => ({
    type: ADD_APPEL_FAILURE,
    payload: error,
});

export const fetchAppels = () => async (dispatch) => {
    dispatch(fetchAppelsRequest());
    try {
        // Récupérer les services
        const appels = await AppelApi.fetchAppels();

        

        // Dispatch de la réponse finale avec les services et leurs fichiers associés
        dispatch(fetchAppelsSuccess(appels.data));
    } catch (error) {
        dispatch(fetchAppelsFailure(error.message));
    }
};

export const fetchAppelsRequest = () => ({
    type: FETCH_APPELS_REQUEST,
});

export const fetchAppelsSuccess = (services) => ({
    type: FETCH_APPELS_SUCCESS,
    payload: services,
});

export const fetchAppelsFailure = (error) => ({
    type: FETCH_APPELS_FAILURE,
    payload: error,
});

