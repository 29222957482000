const BASE_URL = "/api";

export const RappelApi = {
    addRappel: async (rappel) => {
        try {
            const response = await fetch(`${BASE_URL}/prospection/rappel/add`, {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(rappel),
            });

            // Vérifie que la réponse est bien ok
            if (!response.ok) {
                throw new Error("Erreur lors de l'ajout du rappel");
            }

            return await response.json();
        } catch (error) {
            console.error("Erreur API:", error.message);
            return null;
        }
    },
    fetchRappelsAFaire: async() => {
        try {
            const response = await fetch(`${BASE_URL}/prospection/rappels/get`);
            if (!response.ok) {
                throw new Error("Erreur lors de la récupération des rappels à faire.");
            }
            return response.json();
        } catch (error) {
            console.error("Erreur API:", error.message);
            return null;
        }
    }
};
