import { RendezVousApi } from "../../services/RendezVousApi";

// FETCH JOURS CONGÉS
export const fetchJoursCongesRequest = () => ({ type: "FETCH_JOURS_CONGES_REQUEST" });
export const fetchJoursCongesSuccess = (data) => ({ type: "FETCH_JOURS_CONGES_SUCCESS", payload: data });
export const fetchJoursCongesFailure = (error) => ({ type: "FETCH_JOURS_CONGES_FAILURE", payload: error });

export const fetchJoursConges = () => async (dispatch) => {
    dispatch(fetchJoursCongesRequest());
    try {
        const response = await RendezVousApi.getJoursConges();
        dispatch(fetchJoursCongesSuccess(response));
    } catch (error) {
        dispatch(fetchJoursCongesFailure(error.message));
    }
};

// FETCH PÉRIODES CONGÉS
export const fetchPeriodesCongesRequest = () => ({ type: "FETCH_PERIODES_CONGES_REQUEST" });
export const fetchPeriodesCongesSuccess = (data) => ({ type: "FETCH_PERIODES_CONGES_SUCCESS", payload: data });
export const fetchPeriodesCongesFailure = (error) => ({ type: "FETCH_PERIODES_CONGES_FAILURE", payload: error });

export const fetchPeriodesConges = () => async (dispatch) => {
    dispatch(fetchPeriodesCongesRequest());
    try {
        const response = await RendezVousApi.getPeriodesConges();
        dispatch(fetchPeriodesCongesSuccess(response));
    } catch (error) {
        dispatch(fetchPeriodesCongesFailure(error.message));
    }
};

// FETCH CRÉNEAUX OCCUPÉS
export const fetchTakenSlotsRequest = () => ({ type: "FETCH_TAKEN_SLOTS_REQUEST" });
export const fetchTakenSlotsSuccess = (data) => ({ type: "FETCH_TAKEN_SLOTS_SUCCESS", payload: data });
export const fetchTakenSlotsFailure = (error) => ({ type: "FETCH_TAKEN_SLOTS_FAILURE", payload: error });

export const fetchTakenSlots = (date) => async (dispatch) => {
    dispatch(fetchTakenSlotsRequest());
    try {
        const response = await RendezVousApi.getTakenSlots(date);
        dispatch(fetchTakenSlotsSuccess(response));
    } catch (error) {
        dispatch(fetchTakenSlotsFailure(error.message));
    }
};




// FETCH LISTE RENDEZ-VOUS
export const fetchListeRdvRequest = () => ({ type: "FETCH_LISTE_RDV_REQUEST" });
export const fetchListeRdvSuccess = (data) => ({ type: "FETCH_LISTE_RDV_SUCCESS", payload: data });
export const fetchListeRdvFailure = (error) => ({ type: "FETCH_LISTE_RDV_FAILURE", payload: error });

// AJOUT RENDEZ-VOUS
export const addRendezVousRequest = () => ({ type: "ADD_RENDEZ_VOUS_REQUEST" });
export const addRendezVousSuccess = (data) => ({ type: "ADD_RENDEZ_VOUS_SUCCESS", payload: data });
export const addRendezVousFailure = (error) => ({ type: "ADD_RENDEZ_VOUS_FAILURE", payload: error });

export const addRendezVous = (rendezVous) => async (dispatch) => {
    dispatch(addRendezVousRequest());
    try {
        const response = await RendezVousApi.addRdv(rendezVous);
        dispatch(addRendezVousSuccess(response));
    } catch (error) {
        dispatch(addRendezVousFailure(error.message));
    }
};

// ANNULATION RENDEZ-VOUS
export const cancelRendezVousRequest = () => ({ type: "CANCEL_RENDEZ_VOUS_REQUEST" });
export const cancelRendezVousSuccess = (data) => ({ type: "CANCEL_RENDEZ_VOUS_SUCCESS", payload: data });
export const cancelRendezVousFailure = (error) => ({ type: "CANCEL_RENDEZ_VOUS_FAILURE", payload: error });

export const cancelRendezVous = (token) => async (dispatch) => {
    dispatch(cancelRendezVousRequest());
    try {
        const response = await RendezVousApi.cancelRdv(token);
        dispatch(cancelRendezVousSuccess(response));
    } catch (error) {
        dispatch(cancelRendezVousFailure(error.message));
    }
};

// FETCH RENDEZ-VOUS PAR UUID (AJOUTÉ)
export const fetchRdvByUuidRequest = () => ({ type: "FETCH_RDV_BY_UUID_REQUEST" });
export const fetchRdvByUuidSuccess = (data) => ({ type: "FETCH_RDV_BY_UUID_SUCCESS", payload: data });
export const fetchRdvByUuidFailure = (error) => ({ type: "FETCH_RDV_BY_UUID_FAILURE", payload: error });

export const fetchRdvByUuid = (uuid) => async (dispatch) => {
    dispatch(fetchRdvByUuidRequest());
    try {
        const response = await RendezVousApi.getRdvByUuid(uuid);
        dispatch(fetchRdvByUuidSuccess(response));
    } catch (error) {
        dispatch(fetchRdvByUuidFailure(error.message));
    }
};

export const resetRendezVous = ()=>({type: "RESET_RENDEZ_VOUS"})