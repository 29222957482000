const BASE_URL = "/api";

export const ProspectApi = {
    
    fetchProspects: async ({ typeEntreprise, ville, codePostal,departementProspect,estQualifie, page, limit }) => {
        const params = {
            typeEntrepriseProspect: typeEntreprise,
            villeProspect: ville,
            codePostalProspect: codePostal,
            departementProspect: departementProspect,
            estQualifie: estQualifie,
            page,
            limit,
        };
        const queryString = new URLSearchParams(params).toString();
        const response = await fetch(`${BASE_URL}/prospects?${queryString}`);
        if (!response.ok) {
            throw new Error("Erreur lors de la récupération des prospects.");
        }
        return response.json();
    },

    
    generateRandomProspect: async (filters) => {
        // Étape 1 : Récupérer le nombre total de prospects
        const initialResponse = await ProspectApi.fetchProspects({
            ...filters,
            page: 1,
            limit: 1, // Limite à 1 pour obtenir uniquement le total
        });

        const total = initialResponse.nbTotalProspects;

        if (total === 0) {
            throw new Error("Aucun prospect trouvé pour les critères donnés.");
        }

        // Étape 2 : Générer un numéro de page aléatoire
        const randomPage = Math.floor(Math.random() * total) + 1;

        // Étape 3 : Récupérer un prospect aléatoire
        const randomResponse = await ProspectApi.fetchProspects({
            ...filters,
            page: randomPage,
            limit: 1, // Un seul prospect
            timestamp: Date.now(), // Pour éviter le cache
        });

        return randomResponse.prospects[0];
    },
    
    fetchTypesProspects: async () => {
        const response = await fetch(`${BASE_URL}/typeProspect`);
        if (!response.ok) {
            throw new Error("Erreur lors de la récupération des types de prospects.");
        }
        return response.json();
    },

    fetchVillesProspects: async ({  ville,codePostal, departementProspect }) => {
        const params = {
            villeProspect: ville,
            codePostalProspect: codePostal,
            departementProspect: departementProspect,
           
        };
        const queryString = new URLSearchParams(params).toString();
        const response = await fetch(`${BASE_URL}/villeProspect?${queryString}`);
        if (!response.ok) {
            throw new Error("Erreur lors de la récupération des villes.");
        }
        return response.json();
    },

    updateProspect: async(prospect)=>{
        const response = await fetch(`${BASE_URL}/prospects/update`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(prospect),
        });
        
        if (!response.ok) {
            throw new Error("Erreur lors de la mise à jour du prospect.");
        }
        return response.json();
    },
   // /prospection/prospect/setProspectQualifie
   setProspectQualifie: async(prospect)=>{
    const response = await fetch(`${BASE_URL}/prospection/prospect/setProspectQualifie`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(prospect),
    });
    
    if (!response.ok) {
        throw new Error("Erreur lors de la mise à jour du prospect.");
    }
    return response.json();
}
};
