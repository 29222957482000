import { ServiceApi } from '../../services/StatsApi';  // Assurez-vous que le chemin d'importation est correct

// Action Types
const FETCH_STATS_REQUEST = "FETCH_STATS_REQUEST";
const FETCH_STATS_FAILURE = "FETCH_STATS_FAILURE";

// Fonction pour calculer le début et la fin de la semaine, du mois et de l'année
const getWeekRange = (date) => {
    const startOfWeek = new Date(date);
    const day = startOfWeek.getDay();
    const diff = startOfWeek.getDate() - day + (day === 0 ? -6 : 1); // 0 = dimanche, donc on remonte au lundi
    startOfWeek.setDate(diff);
    const endOfWeek = new Date(startOfWeek);
    endOfWeek.setDate(startOfWeek.getDate() + 6);  // Le dimanche de cette semaine
    return { start: startOfWeek.toISOString().split('T')[0], end: endOfWeek.toISOString().split('T')[0] };
};

const getMonthRange = (date) => {
    const startOfMonth = new Date(date);
    startOfMonth.setDate(1);  // Le premier jour du mois
    const endOfMonth = new Date(startOfMonth);
    endOfMonth.setMonth(startOfMonth.getMonth() + 1); // Le premier jour du mois suivant
    endOfMonth.setDate(0);  // Le dernier jour du mois
    return { start: startOfMonth.toISOString().split('T')[0], end: endOfMonth.toISOString().split('T')[0] };
};

const getYearRange = (date) => {
    const startOfYear = new Date(date);
    startOfYear.setMonth(0);  // Janvier
    startOfYear.setDate(1);   // Premier jour de l'année
    const endOfYear = new Date(startOfYear);
    endOfYear.setFullYear(startOfYear.getFullYear() + 1); // Année suivante
    endOfYear.setMonth(0);  
    endOfYear.setDate(0);    // Dernier jour de l'année précédente
    return { start: startOfYear.toISOString().split('T')[0], end: endOfYear.toISOString().split('T')[0] };
};

// Action créateurs
const fetchStatsRequest = () => ({
    type: FETCH_STATS_REQUEST
});

const fetchStatsSuccess = (hour, data) => ({
    type: `FETCH_STATS_APPELS_${hour}_SUCCESS`,
    payload: data
});

const fetchStatsFailure = (error) => ({
    type: FETCH_STATS_FAILURE,
    payload: error
});

// Fonction pour récupérer les statistiques des appels
export const fetchStatsAppels = (date) => async (dispatch) => {
    const filters = {};

    // Calcul des plages de dates pour semaine, mois et année en fonction de la date fournie
    const weekRange = getWeekRange(date);
    const monthRange = getMonthRange(date);
    const yearRange = getYearRange(date);

    // On prépare les filtres pour chaque catégorie (semaine, mois, année)
    filters.weekStart = weekRange.start;
    filters.weekEnd = weekRange.end;
    filters.monthStart = monthRange.start;
    filters.monthEnd = monthRange.end;
    filters.yearStart = yearRange.start;
    filters.yearEnd = yearRange.end;

    // On crée le tableau des heures et catégories pour récupérer les statistiques
    const hours = [9, 10, 11, 14, 15, 16];
    const categories = ["JOUR", "SEMAINE", "MOIS", "ANNEE"];

    try {
        dispatch(fetchStatsRequest());

        // Traitement pour chaque heure
        for (let hour of hours) {
            const filter = {
                date: date,
                heure: hour
            };
            const result = await ServiceApi.getStatsAppels(filter);

            if (result && Object.keys(result).length) {
                dispatch(fetchStatsSuccess(hour, result.data));
            } else {
                dispatch(fetchStatsFailure(`Aucune donnée pour ${hour}h`));
            }
        }

        // Traitement pour chaque catégorie JOUR, SEMAINE, MOIS, ANNEE
        for (let category of categories) {
            let filter = {};
            switch (category) {
                case "JOUR":
                    filter = { date: date };
                    break;
                case "SEMAINE":
                    filter = { dateDebut: filters.weekStart, dateFin: filters.weekEnd };
                    break;
                case "MOIS":
                    filter = { dateDebut: filters.monthStart, dateFin: filters.monthEnd };
                    break;
                case "ANNEE":
                    filter = { dateDebut: filters.yearStart, dateFin: filters.yearEnd };
                    break;
                default:
                    break;
            }

            const result = await ServiceApi.getStatsAppels(filter);

            if (result && Object.keys(result).length) {
                dispatch(fetchStatsSuccess(category, result));
            } else {
                dispatch(fetchStatsFailure(`Aucune donnée pour ${category}`));
            }
        }
    } catch (error) {
        dispatch(fetchStatsFailure(error.message));
    }
};
