import React, { useState } from "react";
import "./starRating.css";
import imgStar from "../../assets/star.png";
import imgStarMoitie from "../../assets/starMoitie.png";
import imgStarVide from "../../assets/starVide.png";

const StarRating = ({ onRatingSubmit, ratingProp = 0, readOnly = false }) => {
  const [rating, setRating] = useState(ratingProp);
  const [,setHover] = useState(0);

  const handleStarClick = (e, i) => {
    if (readOnly) return;

    const rect = e.target.getBoundingClientRect(); // Récupère les dimensions de l'image
    const clickX = e.clientX - rect.left; // Position du clic par rapport à l'image
    const halfWidth = rect.width / 2; // Moitié de l'image

    let newRating = clickX < halfWidth ? i + 0.5 : i + 1; // Demi-étoile ou étoile pleine

    setRating(newRating);
    onRatingSubmit && onRatingSubmit(newRating);
  };

  return (
    <div className="stars">
      {[...Array(5)].map((_, i) => {
        const ratingValue = i + 1;
        const halfValue = i + 0.5;
        let starImg = imgStarVide;

        if (rating >= ratingValue) {
          starImg = imgStar;
        } else if (rating >= halfValue) {
          starImg = imgStarMoitie;
        }

        return (
          <span
            key={i}
            className="star"
            onClick={(e) => handleStarClick(e, i)}
            onMouseEnter={() => !readOnly && setHover(ratingValue)}
            onMouseLeave={() => !readOnly && setHover(0)}
          >
            <img src={starImg} alt="étoile" />
          </span>
        );
      })}
    </div>
  );
};

export default StarRating;
