const BASE_URL = "/api/souscription";
export const SouscriptionApi = {
    addSouscription: async (souscription) => {
        try {
            const response = await fetch(`${BASE_URL}/add`, {
                method: "POST",
                body: souscription, 
            });

            if (!response.ok) {
                throw new Error("Erreur lors de l'ajout de la souscription");
            }
            return await response.json();
        } catch (error) {
            console.error("Erreur API:", error.message);
            return null;
        }
    },
}