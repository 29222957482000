
import GestionRdv from "../../components/GestionRdv";
import "./styles.css";

const PageRendezVous = () => {

    
    return (
        <main>
            <GestionRdv/>
            
        </main>
    );
};

export default PageRendezVous;
