const initialState = {
    joursConges: [],
    periodesConges: [],
    rendezVousPrisEnLigneProspect: null,
    takenSlots: [],
    listeRdv: [],
    loadingJoursConges: false,
    errorJoursConges: null,
    loadingPeriodesConges: false,
    errorPeriodesConges: null,
    loadingTakenSlots: false,
    errorTakenSlots: null,
    loadingListeRdv: false, 
    errorListeRdv: null,
    loadingAddRdv: false,
    errorAddRdv: null,
    loadingDeleteRdv: false,
    errorDeleteRdv: null,
    deleteRdvSuccess: false,
    loadingRdvByUuid: false,
    errorRdvByUuid: null,
    rdvByUuid: null
};

export const rendezVousReducer = (state = initialState, action) => {
    switch(action.type){
        case "FETCH_JOURS_CONGES_REQUEST":
            return { ...state, loadingJoursConges: true, errorJoursConges: null };
        case "FETCH_JOURS_CONGES_SUCCESS":      
            return { ...state, joursConges: action.payload, loadingJoursConges: false, errorJoursConges: null };
        case "FETCH_JOURS_CONGES_FAILURE":
            return { ...state, loadingJoursConges: false, errorJoursConges: action.payload };

        case "FETCH_PERIODES_CONGES_REQUEST":
            return { ...state, loadingPeriodesConges: true, errorPeriodesConges: null };
        case "FETCH_PERIODES_CONGES_SUCCESS":      
            return { ...state, periodesConges: action.payload, loadingPeriodesConges: false, errorPeriodesConges: null };
        case "FETCH_PERIODES_CONGES_FAILURE":
            return { ...state, loadingPeriodesConges: false, errorPeriodesConges: action.payload };

        case "FETCH_TAKEN_SLOTS_REQUEST":
            return { ...state, loadingTakenSlots: true, errorTakenSlots: null };
        case "FETCH_TAKEN_SLOTS_SUCCESS":      
            return { ...state, takenSlots: action.payload, loadingTakenSlots: false, errorTakenSlots: null };
        case "FETCH_TAKEN_SLOTS_FAILURE":
            return { ...state, loadingTakenSlots: false, errorTakenSlots: action.payload };

        case "FETCH_LISTE_RDV_REQUEST":
            return { ...state, loadingListeRdv: true, errorListeRdv: null };
        case "FETCH_LISTE_RDV_SUCCESS":      
            return { ...state, listeRdv: action.payload.listeRdv, loadingListeRdv: false, errorListeRdv: null };
        case "FETCH_LISTE_RDV_FAILURE":
            return { ...state, loadingListeRdv: false, errorListeRdv: action.payload };

        case "ADD_RENDEZ_VOUS_REQUEST":
            return { ...state, loadingAddRdv: true, errorAddRdv: null };
        case "ADD_RENDEZ_VOUS_SUCCESS":
            return { ...state, rendezVousPrisEnLigneProspect: action.payload.rdv, loadingAddRdv: false, errorAddRdv: null };
        case "ADD_RENDEZ_VOUS_FAILURE":
            return { ...state, loadingAddRdv: false, errorAddRdv: action.payload };

        case "CANCEL_RENDEZ_VOUS_REQUEST":
            return { ...state, loadingDeleteRdv: true, errorDeleteRdv: null, deleteRdvSuccess: false };
        case "CANCEL_RENDEZ_VOUS_SUCCESS":
            return { ...state, errorDeleteRdv: null, loadingDeleteRdv: false, rendezVousPrisEnLigneProspect: null, deleteRdvSuccess: true };
        case "CANCEL_RENDEZ_VOUS_FAILURE":
            return { ...state, loadingDeleteRdv: false, errorDeleteRdv: action.payload, deleteRdvSuccess: false };

        // Ajout des cas pour récupérer un RDV par UUID
        case "FETCH_RDV_BY_UUID_REQUEST":
            return { ...state, loadingRdvByUuid: true, errorRdvByUuid: null, rdvByUuid: null };
        case "FETCH_RDV_BY_UUID_SUCCESS":
            return { ...state, rdvByUuid: action.payload.rendezVous, loadingRdvByUuid: false, errorRdvByUuid: null };
        case "FETCH_RDV_BY_UUID_FAILURE":
            return { ...state, loadingRdvByUuid: false, errorRdvByUuid: action.payload };
        case "RESET_RENDEZ_VOUS":
            return { ...initialState };

        default:
            return state;
    }
};
