import React from 'react';
import { useNavigate, useParams } from 'react-router';

const MusicScoreViewer = () => {
  
  const { scoreId } = useParams();
  const navigate = useNavigate()
  // Rendu du contenu HTML dans le composant
  return (
    <main>
      <div>
        <button className='btn-grad' onClick={()=>{navigate("/listeScores")}}>RETOUR A LA LISTE DES PARTITIONS</button>
        <iframe
          src={`https://bonnardwebeditions.fr/api/musicXml/view/${scoreId}`}
          width="100%"
          height="1200px"
          title="Music XML Viewer"
          frameBorder="0"
        ></iframe>
      </div>
    </main>
  );
};


export default MusicScoreViewer;
