const initialState = {
    messageEnvoye:null,
    loading:false,
    error:null
}
export const contactReducer = (state = initialState, action) => {
    switch(action.type){
        case "SEND_MESSAGE_REQUEST":
            return { ...state, loading: true, error: null };
        case "SEND_MESSAGE_SUCCESS":
            return { ...state, messageEnvoye: action.payload, loading: false, error: null };
        case "SEND_MESSAGE_FAILURE":
            return { ...state, messageEnvoye:null, loading: false, error: action.payload };
        case "RESET_MESSAGE":
            return { ...state, loading: false, error:null, messageEnvoye: null}

        default: return state
    }
}