import "./style.css"
import { useDispatch, useSelector } from "react-redux";
//import { useState } from "react";
//import { getRappelsByDate, removeRappel } from "../../redux/actions/rappelActions";
import { setSelectedProspect } from "../../redux/actions/prospectActions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash,faPhone } from '@fortawesome/free-solid-svg-icons';
import { useEffect } from "react";
import { fetchRappels, setSelectedRappel } from "../../redux/actions/rappelActions";

const RappelsAFaire = () => {
    const {listeRappels,loading,error} = useSelector((state) => state.rappel); // Liste des rappels
    //const [selectedDate] = useState(new Date().toISOString().split("T")[0]); // Date du jour

    const dispatch = useDispatch();

    useEffect(() => {
     // Charger les rappels pour la date sélectionnée
         dispatch(fetchRappels());
     }, [ dispatch]);

    return(
        
            !loading && !error && listeRappels && (
                <div className="container-rappels-a-faire">
                <h2>RAPPELS A EFFECTUES</h2>
                <table>
                    
                    <tbody>
                    {listeRappels.map((rappel) => (
                        <tr key={rappel.idRappel}>
                            <td>{rappel.prospect.raisonSocialeProspect}<br/>{rappel.nomContact}<br/>{rappel.bestTimeRappel}<br/>{rappel.raisonDuRappel}</td>

                            <td><FontAwesomeIcon icon={faTrash} onClick={()=>{
                                // dispatch(removeRappel(rappel.id))
                                // dispatch(getRappelsByDate(selectedDate));
    
                            }} />
                            <FontAwesomeIcon icon={faPhone} onClick={()=>{
                                dispatch(setSelectedProspect(rappel.prospect));
                                dispatch(setSelectedRappel(rappel));

                            }}/>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
            ) 
        
        
    )
}
export default RappelsAFaire