import React, { useState } from "react";
import { QRCodeCanvas } from "qrcode.react";
import { useSelector, useDispatch } from "react-redux";
import { generateRandomProspect, clearSelectedProspect, fetchTypeProspects, fetchVillesProspects,fetchProspects,setSelectedProspect, setProspectQualifie } from "../../redux/actions/prospectActions"; // Actions Redux
import "./generateProspect.css";
import { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone } from '@fortawesome/free-solid-svg-icons';
import Loader from "../Loader";
import { clearSelectedRappel } from "../../redux/actions/rappelActions";
function GenerateProspect() {
    const dispatch = useDispatch();
    const { selectedProspect, loading, prospects, typesProspects,villesProspects,loadingTypes,loadingVilles,errorTypes,errorVilles } = useSelector((state) => state.prospect);
    const { nbTotalProspects } = useSelector((state) => state.prospect.prospects);

    const appel = useSelector((state) => state.appel.appel);
    const [searchStarted,setSearchStarted] = useState(false);
    const [showListeProspects, setShowListeProspect] = useState(false);
    const [page, setPage] = useState(1);
    const [limit] = useState(5);
    const [formData, setFormData] = useState({
        typeProspect: "",
        villeProspect: "",
        codePostalProspect:"",
        departementProspect: "",
        estQualifie: false
    });
    const formatTelephone = (telephone) => {
        // Supprime tous les espaces ou caractères non numériques
        const numbersOnly = telephone.replace(/\D/g, "");
        // Vérifie que le numéro est bien composé de 10 chiffres
        if (numbersOnly.length === 10) {
            // Ajoute des espaces tous les 2 chiffres
            return numbersOnly.replace(/(\d{2})(?=\d)/g, "$1 ");
        }
        // Retourne le numéro tel quel s'il ne peut pas être formaté
        return telephone;
    };
    
    const handlePreviousPage = () => {
        if (page > 1) {
          setPage(page - 1);
          handleListeProspects()
        }
      };
    
      const handleNextPage = () => {
        if (page < Math.ceil(nbTotalProspects / limit)) {
          setPage(page + 1);
          handleListeProspects();
        }
      };
    useEffect(() => {
            dispatch(fetchTypeProspects());
        
    }, [dispatch]);

    

    useEffect(() => {
        
        if (formData.villeProspect.length > 2) { // Vérifie si la ville a au moins 3 caractères
            const filters = {
                ville: !isNumerique(formData.villeProspect)?formData.villeProspect:"",
                codePostal: isNumerique(formData.villeProspect)?formData.villeProspect:"",
                departementProspect: formData.departementProspect,
            }
            dispatch(fetchVillesProspects(filters)); // On appelle l'action Redux pour récupérer les villes
        }
        if(formData.villeProspect.length===0){
            formData.villeProspect="";
            formData.codePostalProspect="";
        }
    }, [dispatch, formData.departementProspect,formData.villeProspect,formData.codePostalProspect,formData]); // Dépendance sur `villeProspect`
    
    
    
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
        
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setSearchStarted(true)
        // Générer un prospect aléatoire avec les critères donnés
        const filters = {
            typeEntreprise: formData.typeProspect,
            ville: formData.villeProspect,
            codePostal: formData.codePostalProspect,
            departementProspect: formData.departementProspect,
            estQualifie: formData.estQualifie,
        };

        dispatch(generateRandomProspect(filters));
    };

    const handleListeProspects = (e) => {
        const filters = {
            typeEntreprise: formData.typeProspect,
            ville: formData.villeProspect,
            codePostal: formData.codePostalProspect,
            departementProspect: formData.departementProspect,
            estQualifie: formData.estQualifie,

            limit:limit,
            page:page,

        };
        setShowListeProspect(true)

        dispatch(fetchProspects(filters))
    }
     

    const isNumerique = (valeur) => {
        return !(isNaN(valeur) || valeur === '') 
          
    };

    return (
        <div className={` ${!showListeProspects ? 'prospect-container' : 'liste-prospects-container'}`}>
            {!loading&&!selectedProspect && !showListeProspects&&(
                <div className="formContainer">
                    <div className="formFilterProspect">
                        <div className="inputForm">
                            {!loadingTypes&&!errorTypes&&typesProspects&&(<><label htmlFor="typeProspect">TYPE PROSPECT</label>
                            <select name="typeProspect" id="typeProspect" value={formData.typeProspect} onChange={handleInputChange}>
                                <option key="tous" value="">Tous</option>
                                {typesProspects.map((type)=>(<option key={type}>{type}</option>))}
                            </select>
                            </>)}
                        </div>
                        <div className="inputForm">
                            <label htmlFor="villeProspect">VILLE / CODE POSTALE</label>
                            <input
                                type="text"
                                id="villeProspect"
                                name="villeProspect"
                                value={formData.villeProspect}
                                onChange={handleInputChange}
                            />
                            {!loadingVilles&&!errorVilles&&formData.villeProspect.length > 2 &&villesProspects&&villesProspects.length > 0 && (
                                <select
                                name="villeProspectSelect"
                                id="villeProspectSelect"
                                value={`${formData.villeProspect} (${formData.codePostalProspect})`}
                                onChange={(e) => {
                                    const selectedValue = e.target.value;
                                    const match = selectedValue.match(/^(.*) \((\d+)\)$/); // Utilisation de match pour extraire la ville et le code postal
                
                                    if (match) {
                                        const [ville, codePostal] = match.slice(1, 3); // Récupération des groupes correspondants
                                        setFormData({
                                            ...formData,
                                            villeProspect: ville,
                                            codePostalProspect: codePostal,
                                        });
                                    } else {
                                        // Gérer le cas où la valeur ne correspond pas
                                        setFormData({
                                            ...formData,
                                            villeProspect: "",
                                            codePostalProspect: "",
                                        });
                                    }
                                }}
                            >
                                <option value="">Sélectionner une ville</option>
                                {villesProspects.map((ville) => (
                                    <option
                                        key={`${ville.villeProspect}-${ville.codePostalProspect}`}
                                        value={`${ville.villeProspect} (${ville.codePostalProspect})`}
                                    >
                                        {ville.villeProspect} ({ville.codePostalProspect})
                                    </option>
                                ))}
                            </select>
                            )}
                        </div>
                        
                        <div className="inputForm">
                            <label htmlFor="departementProspect">DÉPARTEMENT</label>
                            <select name="departementProspect" id="departementProspect"  value={formData.departementProspect}
                                onChange={handleInputChange}>
                                <option value="" key="france">France entière</option>
                                <option value="01" key="01">01 - AIN</option>
                                <option value="02" key="02">02 - AISNE</option>
                                <option value="03" key="03">03 - ALLIER</option>
                                <option value="04" key="04">04 - ALPES-DE-HAUTE-PROVENCE</option>
                                <option value="05" key="05">05 - HAUTES-ALPES</option>
                                <option value="06" key="06">06 - ALPES-MARITIMES</option>
                                <option value="07" key="07">07 - ARDÈCHE</option>
                                <option value="08" key="08">08 - ARDENNES</option>
                                <option value="09" key="09">09 - ARIÈGE</option>
                                <option value="10" key="10">10 - AUBE</option>
                                <option value="11" key="11">11 - AUDE</option>
                                <option value="12" key="12">12 - AVEYRON</option>
                                <option value="13" key="13">13 - BOUCHES-DU-RHÔNE</option>
                                <option value="14" key="14">14 - CALVADOS</option>
                                <option value="15" key="15">15 - CANTAL</option>
                                <option value="16" key="16">16 - CHARENTE</option>
                                <option value="17" key="17">17 - CHARENTE-MARITIME</option>
                                <option value="18" key="18">18 - CHER</option>
                                <option value="19" key="19">19 - CORRÈZE</option>
                                <option value="2A" key="2A">2A - CORSE-DU-SUD</option>
                                <option value="2B" key="2B">2B - HAUTE-CORSE</option>
                                <option value="21" key="21">21 - CÔTE-D'OR</option>
                                <option value="22" key="22">22 - CÔTES-D'ARMOR</option>
                                <option value="23" key="23">23 - CREUSE</option>
                                <option value="24" key="24">24 - DORDOGNE</option>
                                <option value="25" key="25">25 - DOUBS</option>
                                <option value="26" key="26">26 - DRÔME</option>
                                <option value="27" key="27">27 - EURE</option>
                                <option value="28" key="28">28 - EURE-ET-LOIR</option>
                                <option value="29" key="29">29 - FINISTÈRE</option>
                                <option value="30" key="30">30 - GARD</option>
                                <option value="31" key="31">31 - HAUTE-GARONNE</option>
                                <option value="32" key="32">32 - GERS</option>
                                <option value="33" key="33">33 - GIRONDE</option>
                                <option value="34" key="34">34 - HÉRAULT</option>
                                <option value="35" key="35">35 - ILLE-ET-VILAINE</option>
                                <option value="36" key="36">36 - INDRE</option>
                                <option value="37" key="37">37 - INDRE-ET-LOIRE</option>
                                <option value="38" key="38">38 - ISÈRE</option>
                                <option value="39" key="39">39 - JURA</option>
                                <option value="40" key="40">40 - LANDES</option>
                                <option value="41" key="41">41 - LOIR-ET-CHER</option>
                                <option value="42" key="42">42 - LOIRE</option>
                                <option value="43" key="43">43 - HAUTE-LOIRE</option>
                                <option value="44" key="44">44 - LOIRE-ATLANTIQUE</option>
                                <option value="45" key="45">45 - LOIRET</option>
                                <option value="46" key="46">46 - LOT</option>
                                <option value="47" key="47">47 - LOT-ET-GARONNE</option>
                                <option value="48" key="48">48 - LOZÈRE</option>
                                <option value="49" key="49">49 - MAINE-ET-LOIRE</option>
                                <option value="50" key="50">50 - MANCHE</option>
                                <option value="51" key="51">51 - MARNE</option>
                                <option value="52" key="52">52 - HAUTE-MARNE</option>
                                <option value="53" key="53">53 - MAYENNE</option>
                                <option value="54" key="54">54 - MEURTHE-ET-MOSELLE</option>
                                <option value="55" key="55">55 - MEUSE</option>
                                <option value="56" key="56">56 - MORBIHAN</option>
                                <option value="57" key="57">57 - MOSELLE</option>
                                <option value="58" key="58">58 - NIEVRE</option>
                                <option value="59" key="59">59 - NORD</option>
                                <option value="60" key="60">60 - OISE</option>
                                <option value="61" key="61">61 - ORNE</option>
                                <option value="62" key="62">62 - PAS-DE-CALAIS</option>
                                <option value="63" key="63">63 - PUY-DE-DÔME</option>
                                <option value="64" key="64">64 - PYRÉNÉES-ATLANTIQUES</option>
                                <option value="65" key="65">65 - HAUTES-PYRÉNÉES</option>
                                <option value="66" key="66">66 - PYRÉNÉES-ORIENTALES</option>
                                <option value="67" key="67">67 - BAS-RHIN</option>
                                <option value="68" key="68">68 - HAUT-RHIN</option>
                                <option value="69" key="69">69 - RHÔNE</option>
                                <option value="70" key="70">70 - HAUTE-SAÔNE</option>
                                <option value="71" key="71">71 - SAÔNE-ET-LOIRE</option>
                                <option value="72" key="72">72 - SARTHE</option>
                                <option value="73" key="73">73 - SAVOIE</option>
                                <option value="74" key="74">74 - HAUTE-SAVOIE</option>
                                <option value="75" key="75">75 - PARIS</option>
                                <option value="76" key="76">76 - SEINE-MARITIME</option>
                                <option value="77" key="77">77 - SEINE-ET-MARNE</option>
                                <option value="78" key="78">78 - YVELINES</option>
                                <option value="79" key="79">79 - DEUX-SÈVRES</option>
                                <option value="80" key="80">80 - SOMME</option>
                                <option value="81" key="81">81 - TARN</option>
                                <option value="82" key="82">82 - TARN-ET-GARONNE</option>
                                <option value="83" key="83">83 - VAR</option>
                                <option value="84" key="84">84 - VAUCLUSE</option>
                                <option value="85" key="85">85 - VENDE</option>
                                <option value="86" key="86">86 - VIENNE</option>
                                <option value="87" key="87">87 - HAUTE-VIENNE</option>
                                <option value="88" key="88">88 - VOSGES</option>
                                <option value="89" key="89">89 - YONNE</option>
                                <option value="90" key="90">90 - TERRITOIRE DE BELFORT</option>
                                <option value="91" key="91">91 - ESSONNE</option>
                                <option value="92" key="92">92 - HAUTS-DE-SEINE</option>
                                <option value="93" key="93">93 - SEINE-SAINT-DENIS</option>
                                <option value="94" key="94">94 - VAL-DE-MARNE</option>
                                <option value="95" key="95">95 - VAL-D'OISE</option>
                            </select>
                            
                        </div>
                        <div className="inputForm">
                            <label htmlFor="estQualifie">Prospect qualifié ?</label>
                            <select name="estQualifie" id="estQualifie" onChange={handleInputChange} value={formData.estQualifie}>
                                <option value="true">Oui</option>
                                <option value="false">Non</option>
                            </select>
                        </div>
                    </div>
                        <button onClick={handleSubmit} disabled={loading}>
                            GÉNÉRER UN PROSPECT
                        </button>
                        <button onClick={handleListeProspects} disabled={loading}>
                            VOIR LA LISTE
                        </button>
                    
                </div>
            )}

            {!loading && prospects && prospects.length === 0 && selectedProspect === null && searchStarted &&(
                <p>Aucun prospect trouvé pour les critères donnés.</p>
            )}

            {
                showListeProspects&&!loading && prospects.prospects && prospects.prospects.length>1&&(
                    <div>
                        <h3>{nbTotalProspects} Prospects trouvés <button
                            className="cancel-button"
                            onClick={() => {
                                setPage(1)
                                setShowListeProspect(false)
                            }
                            }
                        >
                            Annuler
                        </button></h3>
                        <div className="pagination">
                            <button onClick={handlePreviousPage} disabled={page === 1}>Précédent</button>
                            Page {page} sur { Math.ceil(nbTotalProspects / limit)}
                            <button onClick={handleNextPage} disabled={page >=  Math.ceil(nbTotalProspects / limit)}>Suivant</button>
                        </div>
                        <table>
                            <thead>
                            <tr>
                                <th>Raison Sociale</th>
                                <th>Type d'entreprise</th>
                                <th>Adresse</th>
                                <th>Code Postal</th>
                                <th>Ville</th>
                                <th>Téléphone</th>
                                <th>actions</th>
                            </tr>
                            </thead>
                            <tbody>
                            {prospects.prospects.length > 0 ? (
                                prospects.prospects.map((prospect) => (
                                <tr key={prospect.idProspect}>
                                    <td>{prospect.raisonSocialeProspect}</td>
                                    <td>{prospect.typeEntrepriseProspect}</td>
                                    <td>{prospect.adresseProspect}</td>
                                    <td>{prospect.codePostalProspect}</td>
                                    <td>{prospect.villeProspect}</td>
                                    <td>{prospect.telephoneProspect}</td>
                                    <td><FontAwesomeIcon icon={faPhone} onClick={()=>{dispatch(setSelectedProspect(prospect));setShowListeProspect(false)}}/></td>
                                </tr>
                                ))
                            ) : (
                                <tr>
                                <td colSpan="6">Aucun prospect trouvé</td>
                                </tr>
                            )}
                            </tbody>

                        </table>
                    </div>
                )
            }
            {
                loading&&!selectedProspect&&(
                    <Loader/>
                    
                )
            }

            {selectedProspect && (
                <div className="prospectResultContainer">
                    <div className="prospectResult">
                        {appel.isAppelStarted !== true && (
                            <div className="qrcode-container">
                                <QRCodeCanvas value={`tel:${formatTelephone(selectedProspect.telephoneProspect)}`} size={100} />
                            </div>
                        )}
                        <div className="prospectInfos">
                            <p>{selectedProspect.typeEntrepriseProspect}</p>
                            <p>{selectedProspect.raisonSocialeProspect}</p>
                            <p>{formatTelephone(selectedProspect.telephoneProspect)}</p>
                            <p>
                                {selectedProspect.codePostalProspect} {selectedProspect.villeProspect}
                            </p>
                            <button
                                onClick={() => {
                                    const query = `${selectedProspect.raisonSocialeProspect} ${selectedProspect.adresseProspect} ${selectedProspect.codePostalProspect} ${selectedProspect.villeProspect} `;
                                    window.open(`https://www.google.com/search?q=${encodeURIComponent(query)
                                }`, '_blank')}}
                                className="bg-blue-500 text-white px-4 py-2 rounded"
                                >
                                Rechercher sur Google
                            </button>
                            <button className="bg-blue-500 text-white px-4 py-2 rounded" onClick={()=>{
                                dispatch(setProspectQualifie(selectedProspect))
                            }}>prospect qualifié</button>

                        </div>
                    </div>
                    {appel.isAppelStarted !== true && (
                        <button
                            className="cancel-button"
                            onClick={() => {
                                dispatch(clearSelectedProspect())
                                dispatch(clearSelectedRappel())
                                setSearchStarted(false)
                            }
                            }
                        >
                            Annuler
                        </button>
                    )}
                </div>
            )}
        </div>
    );
}

export default GenerateProspect;
