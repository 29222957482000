import { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { fr } from "date-fns/locale";
import { useSelector, useDispatch } from "react-redux";
import { fetchJoursConges,fetchPeriodesConges,fetchTakenSlots, addRendezVous, cancelRendezVous,resetRendezVous,fetchRdvByUuid } from "../../redux/actions/rendezVousActions";
import { isSameDay, format } from "date-fns";
import { sendMessage } from "../../redux/actions/contactActions";
import { useParams } from "react-router";
const GestionRdv = ()=>{
    const [availableSlots, setAvailableSlots] = useState([]);
    const {joursConges,periodesConges,takenSlots,rendezVousPrisEnLigneProspect,loadingAddRdv,deleteRdvSuccess,rdvByUuid} = useSelector((state) => state.rendezVousReducer);
    const dispatch = useDispatch();
    const { uuid } = useParams();
    const [cancelRequest,setCancelRequest]=useState(false);
    const [formData, setFormData] = useState({
        nomProspect: "",
        prenomProspect: "",
        raisonSocialeProspect: "",
        emailProspect: "",
        telephoneProspect: "",
        dateRdv: null,
        typeRdv: "rdv en ligne",
        heureRdv: "",
        modeRdv:"",
        dureeRdv: 30
    });
    useEffect(()=>{
        if(uuid){
            dispatch(fetchRdvByUuid(uuid))
        }
    },[uuid,dispatch])
    // const joursDeConge = [
    //     new Date(2025, 0, 1),
    //     new Date(2025, 4, 1),
    //     new Date(2025, 11, 25),
    //     new Date(2025, 6, 14),
    // ];

    
    const isSelectableDate = (date) => {
        const day = date.getDay();
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        const selectedDate = new Date(date);
        selectedDate.setHours(0, 0, 0, 0);
    
        // Si les données ne sont pas encore chargées, ne pas bloquer les dates
        if (!joursConges || !periodesConges) {
            return day !== 0 && day !== 6 && selectedDate.getTime() !== today.getTime();
        }
    
        // Bloquer les jours fériés et les périodes de congés
        const isJourFerie = joursConges.some((conge) => new Date(conge).toDateString() === selectedDate.toDateString());
    
        const isPeriodeConge = periodesConges.some(({ dateDebutConge, dateFinConge }) => {
            const startDate = new Date(dateDebutConge + "T00:00:00");
            const endDate = new Date(dateFinConge + "T23:59:59");
            return selectedDate >= startDate && selectedDate <= endDate;
        });
    
        return !(selectedDate.getTime() === today.getTime() || day === 0 || day === 6 || isJourFerie || isPeriodeConge);
    };
    
    
    const handleCancelRdv = () => {
        if(!cancelRequest)
            setCancelRequest(true)
        else{
            if(rdvByUuid)
                dispatch(cancelRendezVous(rdvByUuid.uuid))
            else
                dispatch(cancelRendezVous(rendezVousPrisEnLigneProspect.uuid))

        }
    }
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleDateChange = (date) => {
        setFormData({ ...formData, dateRdv: date });
    };

    const handleAddRendezVous = async () => {
        const heureRdv = document.getElementById("heureRdv").value;

        if (formData.nomProspect && formData.prenomProspect && formData.dateRdv && formData.emailProspect && formData.telephoneProspect && heureRdv) {
            const heureFormat = heureRdv.replace("H", ":");
            const newRendezVous = {
                nomProspect: formData.nomProspect,
                prenomProspect: formData.prenomProspect,
                raisonSocialeProspect: formData.raisonSocialeProspect,
                emailProspect: formData.emailProspect,
                telephoneProspect: formData.telephoneProspect,
                dateRdv: format(new Date(formData.dateRdv), "yyyy-MM-dd"),
                heureRdv: heureFormat,
                typeRdv: formData.typeRdv,
                dureeRdv: formData.dureeRdv,
                modeRdv: formData.modeRdv,
            };

            dispatch(addRendezVous(newRendezVous))
        } else {
            alert("Veuillez remplir tous les champs.");
        }
    };
    useEffect(() => {
        if (formData.dateRdv) {
            dispatch(fetchTakenSlots(format(formData.dateRdv, "yyyy-MM-dd")));
        }
    }, [formData.dateRdv, dispatch]);
    useEffect(()=>{
        dispatch(fetchJoursConges())
        dispatch(fetchPeriodesConges())
    },[dispatch])
    
    useEffect(() => {
        if(takenSlots){
            const allSlots = [
                "09H00", "09H30", "10H00", "10H30", "11H00", "11H30",
                "14H00", "14H30", "15H00", "15H30", "16H00", "16H30",
                "17H00", "17H30"
            ];
            
            // Transformer les créneaux de "HH:MM:SS" en "HHHMM"
            const takenSlotFormatted = takenSlots.map(slot => {
                const [hour, minute] = slot.split(":"); // Extraire heure et minute
                return `${hour}H${minute}`; // Reformater en "HHHMM"
            });
        
            let freeSlots = allSlots.filter(slot => !takenSlotFormatted.includes(slot));
        
            // Supprimer les créneaux passés si la date est aujourd'hui
            if (formData.dateRdv && isSameDay(formData.dateRdv, new Date())) {
                const currentTime = new Date();
                const currentHour = currentTime.getHours();
                const currentMinute = currentTime.getMinutes();
        
                freeSlots = freeSlots.filter((slot) => {
                    const [hourPart, minutePart] = slot.split("H").map(Number);
                    return hourPart > currentHour || (hourPart === currentHour && minutePart > currentMinute);
                });
            }
        
            setAvailableSlots(freeSlots);
        }
       
    }, [takenSlots, formData.dateRdv]);

    useEffect(() => {
        if (rendezVousPrisEnLigneProspect) {
            const data = new FormData();
            
            data.append("nomProspect", rendezVousPrisEnLigneProspect.nomProspect);
            data.append("prenomProspect", rendezVousPrisEnLigneProspect.prenomProspect);
            data.append("raisonSocialeProspect", rendezVousPrisEnLigneProspect.raisonSocialeProspect);
            data.append("emailProspect", rendezVousPrisEnLigneProspect.mailProspect);
            data.append("telephoneProspect", rendezVousPrisEnLigneProspect.telephoneProspect);
            data.append("dateRdv", rendezVousPrisEnLigneProspect.dateRdv);
            data.append("heureRdv", rendezVousPrisEnLigneProspect.heureRdv);
            data.append("uuid", rendezVousPrisEnLigneProspect.uuid);            
            data.append("typeEmail", "confirmRdv");
            data.append("modeRdv",rendezVousPrisEnLigneProspect.modeRdv);
            dispatch(sendMessage(data));
            
        }
    }, [rendezVousPrisEnLigneProspect, dispatch]);
    
    return( 
        <>
        {
                !rendezVousPrisEnLigneProspect&&!rdvByUuid&&!loadingAddRdv&&!cancelRequest&&(
                    <section className="formRendezVous">
                        <div className="form-input-date-heure">
                            <div className="form-input">
                                <label htmlFor="dateRdv">DATE DE RENDEZ-VOUS :</label>
                                <DatePicker name="dateRdv" id="dateRdv" selected={formData.dateRdv} onChange={handleDateChange} minDate={new Date()} locale={fr} filterDate={isSelectableDate} dateFormat="dd/MM/yyyy" placeholderText="Choisissez une date" required />
                            </div>
                            <div className="form-input">
                                <label htmlFor="heureRdv">HEURE DU RENDEZ VOUS :</label>
                                <select name="heureRdv" id="heureRdv">
                                    {availableSlots.map((slot) => (
                                        <option key={slot} value={slot}>{slot}</option>
                                    ))}
                                </select>
                            </div>
                        </div>

                <div className="form-input">
                                <label htmlFor="modeRdv">MODE DE RENDEZ VOUS :</label>
                                <select name="modeRdv" id="modeRdv" value={formData.modeRdv} onChange={handleChange}>
                                    <option value="">Sélectionner le mode de rendez vous</option>
                                    <option value="visio">Visio(teams)</option>
                                    <option value="telephone">Appel téléphonique</option>
                                </select>
                            </div>
                <div className="form-input">
                    <label htmlFor="nomProspect">NOM :</label>
                    <input name="nomProspect" id="nomProspect" type="text" value={formData.nomProspect} onChange={handleChange} required />
                </div>
                <div className="form-input">
                    <label htmlFor="prenomProspect">PRENOM :</label>
                    <input name="prenomProspect" id="prenomProspect" type="text" value={formData.prenomProspect} onChange={handleChange} required />
                </div>
                <div className="form-input">
                    <label htmlFor="raisonSocialeProspect">RAISON SOCIALE :</label>
                    <input name="raisonSocialeProspect" id="raisonSocialeProspect" type="text" value={formData.raisonSocialeProspect} onChange={handleChange} />
                </div>
                <div className="form-input">
                    <label htmlFor="emailProspect">ADRESSE EMAIL :</label>
                    <input name="emailProspect" id="emailProspect" type="email" value={formData.emailProspect} onChange={handleChange} />
                </div>
                <div className="form-input">
                    <label htmlFor="telephoneProspect">TELEPHONE :</label>
                    <input name="telephoneProspect" id="telephoneProspect" type="text" value={formData.telephoneProspect} onChange={handleChange} />
                </div>
                
                
                <div className="form-input">
                    <button className="btn-grad" onClick={handleAddRendezVous}>ENREGISTRER LE RENDEZ-VOUS</button>
                </div>
            </section>
                )
                
                
            }
            {
                rendezVousPrisEnLigneProspect&&!rdvByUuid&&!cancelRequest&&(
                    <section className="rdvConfirmation">
                        <p>Je vous confirme la prise en compte de votre rendez-vous pour le {format(rendezVousPrisEnLigneProspect.dateRdv,"dd/MM/yyyy")} à {rendezVousPrisEnLigneProspect.heureRdv}.</p>
                        <p>Le jour du rendez-vous, je vous contacterai par téléphone au {rendezVousPrisEnLigneProspect.telephoneProspect} depuis le numéro 06 52 37 53 92 afin de discuter de votre projet.</p>
                        <p>Merci de l'intérêt que vous portez à mes services. Vous pouvez annuler ce rendez-vous à tout moment depuis cette page en cliquant sur le bouton ci-dessous ou via le lien figurant dans l'e-mail de confirmation que vous avez dû recevoir.</p>
                        <button 
                        onClick={handleCancelRdv}
                        className="btn-grad"
                    >
                        ANNULER LE RENDEZ-VOUS
                    </button>
                    </section>
                )
            }
            {
               rdvByUuid&&!cancelRequest&&(
                    <section className="rdvConfirmation">
                        <p>Vous pouvez annuler votre rendez-vous du {format(rdvByUuid.dateRdv,"dd/MM/yyyy")} à {rdvByUuid.heureRdv} en cliquant sur le bouton ci-dessous.</p>
                         <button 
                        onClick={handleCancelRdv}
                        className="btn-grad"
                    >
                        ANNULER LE RENDEZ-VOUS
                    </button>
                    </section>
                )
            }
            {
                cancelRequest&&!deleteRdvSuccess&&(rendezVousPrisEnLigneProspect||rdvByUuid)&&(
                    <section className="rdvConfirmation">
                        <p>Vous êtes sur le point de supprimer votre rendez-vous du {format(rdvByUuid?rdvByUuid.dateRdv:rendezVousPrisEnLigneProspect.dateRdv,"dd/MM/yyyy")} à {rdvByUuid?rdvByUuid.heureRdv:rendezVousPrisEnLigneProspect.heureRdv}, confirmez vous cette opération ?</p>
                        <button className="btn-grad" onClick={()=>{
                            handleCancelRdv()

                            }}>OUI</button>
                        <button className="btn-grad" onClick={
                            ()=>{
                                setCancelRequest(false)
                            }
                        }>
                            NON
                        </button>
                    </section>
                )
            }
            {
                deleteRdvSuccess&&cancelRequest&&!rendezVousPrisEnLigneProspect&&(
                    <section className="rdvConfirmation">
                        <p>Je vous confirme l'annulation de votre rendez-vous, n'hésitez pas à reprendre rendez-vous si vous souhaitez discuter de votre projet</p>
                        <button className="btn-grad" onClick={()=>{
                            dispatch(resetRendezVous());
                            setCancelRequest(false);
                            formData.nomProspect="";
                            formData.dateRdv="";
                            formData.prenomProspect="";
                            formData.emailProspect="";
                            formData.raisonSocialeProspect="";
                            formData.telephoneProspect="";
                        }}>
                            OK
                        </button>
                    </section>
                )
            }
        </>
    )
}

export default GestionRdv