const BASE_URL = "/api/statistics";
export const ServiceApi = {
    getStatsAppels : async (filters) => {
        try {
            const response = await fetch(`${BASE_URL}/getCallStats`, {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify( filters ),

            });
    
    
            return response.json();
        } catch (error) {
            console.error("Erreur API:", error.message);
            return null; // Retourne un tableau vide en cas d'erreur
        }
    }
}