export const START_APPEL = "START_APPEL";
export const FINISH_APPEL = "FINISH_APPEL";
export const UPDATE_APPEL_DATA = "UPDATE_APPEL_DATA";
export const RESET_APPEL = "RESET_APPEL";
export const ADD_APPEL_REQUEST = "ADD_APPEL_REQUEST";
export const ADD_APPEL_SUCCESS = "ADD_APPEL_SUCCESS";
export const ADD_APPEL_FAILURE = "ADD_APPEL_FAILURE";
export const FETCH_APPELS_REQUEST = "FETCH_APPELS_REQUEST";
export const FETCH_APPELS_SUCCESS = "FETCH_APPELS_SUCCESS";
export const FETCH_APPELS_FAILURE = "FETCH_APPELS_FAILURE";

const initialState = {
    listAppels:[],
    appel:{
        idAppel:null,
        isAppelStarted:false,
        isAppelFinished:false,
        idProspect:null,
        idUser:null,
        dateAppel:null,
        heureDebutAppel:null,
        heureFinAppel:null,
        contactAppel:null,
        noteAppel:null,
        bilanAppel:null,
        isResponsableDisponible:null,
        isProspectOk:null,
        isProspectNotResponding:null,
        isSiteExistant:null,
        
    },
    loading:false,
    error: null,
}
export const appelReducer = (state = initialState, action) => {
    switch (action.type) {
        case START_APPEL:
            return {
                ...state,
                appel: {
                    ...state.appel,
                    isAppelStarted: true,
                    isAppelFinished: false,
                    idProspect: action.payload.idProspect,
                    idUser: action.payload.idUser,
                    dateAppel: action.payload.dateAppel,
                    heureDebutAppel: action.payload.heureDebutAppel,
                },
            };

        case FINISH_APPEL:
            return {
                ...state,
                appel: {
                    ...state.appel,
                    isAppelFinished: true,
                    heureFinAppel: action.payload.heureFinAppel,
                },
            };

        case UPDATE_APPEL_DATA:
            return {
                ...state,
                appel: {
                    ...state.appel,
                    ...action.payload, // Mise à jour des champs de l'appel
                },
            };

        
        case RESET_APPEL:
            return {
                ...state,
                appel: { ...initialState.appel }, // Réinitialise l'état de l'appel
            };

        case FETCH_APPELS_REQUEST:
            return { ...state, loading: true, error: null };
        case FETCH_APPELS_SUCCESS:
            return { ...state, services: action.payload, loading: false, error: null };
        case FETCH_APPELS_FAILURE:
            return { ...state, loading: false, error: action.payload };
        case ADD_APPEL_REQUEST:
            return { ...state, loading: true, error: null };
        case ADD_APPEL_SUCCESS:
            return { ...state, loading: false, error: null };
        case ADD_APPEL_FAILURE:
            return { ...state, loading: false, error: action.payload };

        default:
            return state;
    }
};
