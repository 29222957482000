import "./styles.css"
function AdminNavigation({ ongletActive, setOngletActive }) {
    const tabs = [
        { id: "statistiques", label: "Mes Visites" },
        { id: "clients", label: "Mes Clients" },
        { id: "prospection", label: "Prospection" },
        { id: "factures", label: "Revenus" },
        { id: "messages", label: "Messagerie" },
        { id: "services", label: "Services" },
    ];

    return (
        <nav>
            <ul>
            {tabs.map((tab) => (
                <li
                    key={tab.id}
                    className={ongletActive === tab.id ? "active" : ""}
                    onClick={() => setOngletActive(tab.id)}
                >
                    {tab.label}
                </li>
            ))}
            </ul>
        </nav>
    );
}
export default AdminNavigation