import { ClientsApi } from "../../services/ClientsApi.js";

// Action Types
export const FETCH_CLIENTS_REQUEST = "FETCH_CLIENTS_REQUEST";
export const FETCH_CLIENTS_SUCCESS = "FETCH_CLIENTS_SUCCESS";
export const FETCH_CLIENTS_FAILURE = "FETCH_CLIENTS_FAILURE";

// Action Creators
export const fetchClientsRequest = () => ({
    type: FETCH_CLIENTS_REQUEST,
});

export const fetchClientsSuccess = (data) => ({
    type: FETCH_CLIENTS_SUCCESS,
    payload: data,
});

export const fetchClientsFailure = (error) => ({
    type: FETCH_CLIENTS_FAILURE,
    payload: error,
});

// Thunks
export const fetchClients = (filters) => async (dispatch) => {
    dispatch(fetchClientsRequest());
    try {
        const response = await ClientsApi.fetchClients(filters);
        dispatch(fetchClientsSuccess(response.data));
    } catch (error) {
        dispatch(fetchClientsFailure(error.message));
    }
};

