import { SouscriptionApi } from "../../services/SouscriptionApi";
import { fetchClients } from "./clientsActions";
export const ADD_SOUSCRIPTION_REQUEST = "ADD_SOUSCRIPTION_REQUEST";
export const ADD_SOUSCRIPTION_SUCCESS = "ADD_SOUSCRIPTION_SUCCESS";
export const ADD_SOUSCRIPTION_FAILURE = "ADD_SOUSCRIPTION_FAILURE";

export const addSouscription = (souscription) => async (dispatch) => {
    dispatch(addSouscriptionRequest());
    try {
        await SouscriptionApi.addSouscription(souscription);
        dispatch(addSouscriptionSuccess());
        dispatch(fetchClients());
    } catch (error) {
        dispatch(addSouscriptionFailure(error.message));
    }
};
export const addSouscriptionRequest = () => ({
    type: ADD_SOUSCRIPTION_REQUEST,
});

export const addSouscriptionSuccess = () => ({
    type: ADD_SOUSCRIPTION_SUCCESS,
});

export const addSouscriptionFailure = (error) => ({
    type: ADD_SOUSCRIPTION_FAILURE,
    payload: error,
});
