
const initialState = {
    selectedProspect: null, // Le prospect actuellement sélectionné
    prospects: [], // La liste des prospects
    typesProspects: [], // La liste des types de prospects
    villesProspects:[],
    loading: false, // Indicateur de chargement global
    loadingTypes: false, // Indicateur de chargement pour les types de prospects
    loadingVilles: false,
    error: null, // Message d'erreur général
    errorTypes: null, // Message d'erreur pour les types de prospects
    errorVilles: null,
};

export const prospectReducer = (state = initialState, action) => {
    switch (action.type) {
        case "FETCH_PROSPECTS_REQUEST":
            return {
                ...state,
                loading: true,
                error: null,
            };
        case "FETCH_PROSPECTS_SUCCESS":
            return {
                ...state,
                prospects: action.payload.prospects,
                loading: false,
                error: null,
            };
        case "FETCH_PROSPECTS_FAILURE":
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case "SET_SELECTED_PROSPECT":
            return {
                ...state,
                selectedProspect: action.payload,
                error: null,
            };
        case "CLEAR_SELECTED_PROSPECT":
            return {
                ...state,
                selectedProspect: null,
                loading: false,
            };
        case "FETCH_TYPES_PROSPECTS_REQUEST":
            return {
                ...state,
                loadingTypes: true,
                errorTypes: null,
            };
        case "FETCH_TYPES_PROSPECTS_SUCCESS":
            return {
                ...state,
                typesProspects: action.payload.typesProspects,
                loadingTypes: false,
                errorTypes: null,
            };
        case "FETCH_TYPES_PROSPECTS_FAILURE":
            return {
                ...state,
                loadingTypes: false,
                errorTypes: action.payload,
            };
        case "FETCH_VILLES_PROSPECTS_REQUEST":
            return {
                ...state,
                loadingVilles: true,
                errorVilles: null,
            };
        case "FETCH_VILLES_PROSPECTS_SUCCESS":
            return {
                ...state,
                villesProspects: action.payload.villesProspects,
                loadingVilles: false,
                errorVilles: null,
            };
        case "FETCH_VILLES_PROSPECTS_FAILURE":
            return {
                ...state,
                loadingVilles: false,
                errorVilles: action.payload,
            };

        case "UPDATE_PROSPECT_REQUEST":
            return {
                ...state,
                loading: true,
                error:null,
            }
        case "UPDATE_PROSPECT_SUCCESS":
            return {
                ...state,
                loading:false,
                error:null,
                selectedProspect:action.payload.updatedProspect
            }
        case "UPDATE_PROSPECT_FAILURE":
            return {
                ...state,
                loading: false,
                error:action.payload.error,
                selectedProspect:action.payload.updatedProspect,
            }
        case "SET_PROSPECT_QUALIFIE_REQUEST":
            return {
                ...state,
                loading: true,
                error:null,
            }
        case "SET_PROSPECT_QUALIFIE_SUCCESS":
            return {
                ...state,
                loading:false,
                error:null,
            }
        case "SET_PROSPECT_QUALIFIE_FAILURE":
            return {
                ...state,
                loading: false,
                error:action.payload.error,
            }
        
        default:
            return state;
    }
};
