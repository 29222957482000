const initialState = {
    clients :[],
    loading: false,
    error: null
}


export const clientsReducer = (state = initialState, action) => {
    switch (action.type) {
        case "FETCH_CLIENTS_REQUEST":
            return {
                ...state,
                loading: true,
                error: null,
            };
        case "FETCH_CLIENTS_SUCCESS":
            return {
                ...state,
                clients: action.payload,
                loading: false,
                error: null,
            };
        case "FETCH_CLIENTS_FAILURE":
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        default:
            return state
            
    }
}