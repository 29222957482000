import { useEffect, useState } from "react"
import "./styles.css"
import { useDispatch, useSelector } from "react-redux";
import { addService, deleteService, fetchServices } from "../../redux/actions/serviceAction.jsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from '@fortawesome/free-solid-svg-icons';
const GestionServices = () => {
    const dispatch = useDispatch();

    const { services, loading, error } = useSelector((state) => state.serviceReducer);

    useEffect(() => {
        dispatch(fetchServices());
    }, [dispatch]);

    const initialData = useState({
        "nomService":"",
        "descriptionService":"",
        "tarifService":0.00,
        "periodiciteService":"",
        "nbMoisEngagementService":0,
        "conditionsGeneralesService":null,
        "isActive":true,


    })
    const [formData, setFormData] = useState(
        initialData
    )
   
    
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
        
    };

    // Gestion de l'upload de fichier
    const handleFileChange = (e) => {
        const file = e.target.files[0];
        setFormData({
            ...formData,
            conditionsGeneralesService: file, // Stocke le fichier PDF
        });
    };
    const handleOpenFile = (condition) => {
        if (!condition) return;
    
        // Construire l'URL de récupération du fichier
        const fileUrl = `https://bonnardwebeditions.fr/api/file/pdf/get/?path=${encodeURIComponent(condition)}`;
    
        // Ouvrir le PDF dans un nouvel onglet
        window.open(fileUrl, "_blank");
    };
    
    const handleSubmit = (e) => {
        e.preventDefault();
    
        const data = new FormData();
        data.append("nomService", formData.nomService);
        data.append("descriptionService", formData.descriptionService);
        data.append("tarifService", formData.tarifService);
        data.append("periodiciteService", formData.periodiciteService);
        data.append("nbMoisEngagementService", formData.nbMoisEngagementService);
        data.append("isActive", formData.isActive);
    
        // Vérifie si un fichier est sélectionné avant de l'ajouter
        if (formData.conditionsGeneralesService) {
            data.append("conditionsGeneralesService", formData.conditionsGeneralesService);
        }
    
        dispatch(addService(data));
        setFormData(initialData);
    };
    const handleDeleteService = (idService)=>{
        dispatch(deleteService(idService));
    }
    if (loading) return <div>Chargement...</div>;
    if (error) return <div>Erreur: {error}</div>;
 
    return(
        <div className="services-section">
            <div className="form-service">
                <h2>AJOUTER UN SERVICE</h2>
                <div className="input-form">
                    <label htmlFor="nomService">NOM DU SERVICE</label>
                    <input 
                        type="text" 
                        id="nomService"
                        name="nomService"
                        value={formData.nomService}
                        onChange={handleInputChange}
                    />
                </div>
                <div className="input-form">
                    <label htmlFor="descriptionService">DESCRIPTION DU SERVICE</label>
                    <textarea
                    id="descriptionService"
                    name="descriptionService"
                    value={formData.descriptionService}
                    onChange={handleInputChange}
                    rows="5"
                    placeholder="Décrivez votre service"
                />
                </div>
                <div className="input-form">
                    <label htmlFor="tarifService">DESCRIPTION DU SERVICE</label>
                    <input
                    type="number"
                    id="tarifService"
                    name="tarifService"
                    value={formData.tarifService}
                    onChange={handleInputChange}
                    step="0.01" 
                    min="0"
                    />
                </div>
                <div className="input-form">
                    <label htmlFor="periodiciteService">PERIODICITE DU SERVICE (FACTURATION)</label>
                    <select name="periodiciteService" id="periodiciteService" value={formData.periodiciteService} onChange={handleInputChange}>
                        <option value="">SELECTIONNER UNE OPTION</option>
                        <option value="unique">PAIEMENT UNIQUE</option>
                        <option value="mensuel">PAIEMENT MENSUEL</option>
                        <option value="annuel">PAIEMENT ANNUEL</option>
                    </select>
                </div>
                <div className="input-form">
                    <label htmlFor="nbMoisEngagementService">NOMBRE DE MOIS D'ENGAGEMENT</label>
                    <input
                    type="number"
                    id="nbMoisEngagementService"
                    name="nbMoisEngagementService"
                    value={formData.nbMoisEngagementService}
                    onChange={handleInputChange}
                    min="0"
                    />
                </div>
                {/* Ajout du champ fichier */}
                <div className="input-form">
                    <label htmlFor="conditionsGeneralesService">CONDITIONS GÉNÉRALES (PDF)</label>
                    <input type="file" id="conditionsGeneralesService" name="conditionsGeneralesService" accept=".pdf" onChange={handleFileChange} />
                </div>

                <div className="input-form">
                    <label htmlFor="isActive">SERVICE ACTIF ?</label>
                    <select name="isActive" id="isActive" value={formData.isActive} onChange={handleInputChange}>
                        <option value="true">OUI</option>
                        <option value="false">NON</option>
                    </select>
                </div>

                <button onClick={handleSubmit} >Ajouter le service</button>
            </div>
            <div>
                
            <h2>Services</h2>
            <table className="table-services">
                <thead>
                    <tr>
                        <th>Nom service</th>
                        <th>Description</th>
                        <th>Tarif</th>
                        <th>Periodicité</th>
                        <th>Nombre de mois d'engagement</th>
                        <th>Conditions générales</th>
                        <th>Service actif ?</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                {Array.isArray(services) && services.map((service) => (
                            <tr key={service.id}>
                                <td>{service.nomService}</td>
                                <td>{service.descriptionService}</td>
                                <td>{service.tarifService} €</td>
                                <td>{service.periodiciteService}</td>
                                <td>{service.nbMoisEngagementService}</td>
                                <td>
                                    {service.conditionsGeneralesService && (
                                        <span 
                                            onClick={() => handleOpenFile(service.conditionsGeneralesService)} 
                                            style={{ cursor: "pointer", color: "blue", textDecoration: "underline" }}
                                        >
                                            Voir les conditions
                                        </span>
                                    )}                                
                                </td>
                                <td>{service.isActive ? "Oui" : "Non"}</td>
                                <td><FontAwesomeIcon icon={faTrash} onClick={()=>handleDeleteService(service.idService)}/></td>
                            </tr>
                        ))}
                </tbody>
            </table>
            </div>
        </div>
    )
}
export default GestionServices