import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { persistStore, persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { appelReducer } from './reducers/appelReducer';
import { prospectReducer } from './reducers/prospectReducer'; // Importer le prospectReducer
import { rappelReducer } from './reducers/rappelReducer';
import { rendezVousReducer } from './reducers/rendezVousReducer';
import { serviceReducer } from './reducers/serviceReducer';
import { clientsReducer } from './reducers/clientsReducer';
import { souscriptionReducer } from './reducers/souscriptionReducer';
import { contactReducer } from './reducers/contactReducer';
import { statsReducer } from './reducers/statsReducer';

// Combiner plusieurs reducers
const rootReducer = combineReducers({
    appel: appelReducer, // Gestion des appels
    prospect: prospectReducer, // Gestion des prospects
    rappel: rappelReducer,
    rendezVousReducer: rendezVousReducer,
    serviceReducer: serviceReducer,
    clientsReducer: clientsReducer,
    souscriptionReducer: souscriptionReducer,
    contactReducer: contactReducer,
    statsReducer : statsReducer,
});

const persistConfig = {
    key: "root",
    storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
            },
        }),
});
const persistor = persistStore(store);
persistor.purge();

export {persistor}
