import FormConnexion from "../../components/FormConnexion";
import { useAuth } from "../../context/AuthContext";
import { useState } from "react";
import "./espaceAdmin.css";
import AdminNavigation from "../../components/AdminNavigation";

import StatisticsChart from "../../components/StatisticsChart";
import AddVisits from "../../components/AddVisits";
import GenerateProspect from "../../components/GenerateProspect";
import InfosAppelsEffectues from "../../components/InfosAppelsEffectues";
import RappelsAFaire from "../../components/RappelsAFaire";
import { useSelector } from "react-redux";
import GestionAppelProspect from "../../components/GestionAppelProspect";
import GestionServices from "../../components/GestionServices";
import GestionClients from "../../components/GestionClients";

function EspaceAdmin() {
    const { userConnected } = useAuth();
    const [ongletActive, setOngletActive] = useState("prospection");
    const selectedProspect = useSelector((state) => state.prospect.selectedProspect);

   

    return (
        <main>
            {/* Si l'utilisateur n'est pas connecté */}
            {!userConnected ? (
                <FormConnexion title="CONNEXION ADMINISTRATEUR" />
            ) : userConnected.roleUser !== "superAdmin" ? (
                // Accès refusé
                <div className="access-denied">
                    <h1>Accès Refusé</h1>
                    <p>Vous n'avez pas les droits nécessaires pour accéder à cet espace.</p>
                </div>
            ) : (
                // Tableau de bord principal
                <div className="main-container">
                    {/* Menu de navigation à gauche */}
                    <div className="dashboard-admin">
                        <AdminNavigation
                            ongletActive={ongletActive}
                            setOngletActive={setOngletActive}
                        />
                    </div>

                    {/* Contenu principal */}
                    <section>
                        {ongletActive === "statistiques" && (
                            <div className="statistiques-section">
                                <StatisticsChart />
                                <AddVisits />
                            </div>
                        )}

                        {ongletActive === "prospection" && (
                            <div className="prospection-section">
                                <div className="prospect-infos-appels">
                                <GenerateProspect />
                                <InfosAppelsEffectues/>
                                </div>
                                <div className="main-content-prospection">
                                {
                                    selectedProspect&&(
                                        <GestionAppelProspect prospect={selectedProspect} />
                                    )
                                }
                                </div>
                                   
                                            <div className="container-infos-rappels">
                                                
                                                <RappelsAFaire/>
                                            </div>
                                        
                                    
                                
                                
                            </div>
                        )}

                        {ongletActive === "clients" && (
                            <GestionClients/>
                        )}

                        {ongletActive === "factures" && (
                            <div className="factures-section">
                                <h2>Statistiques de revenus</h2>
                                <p>Gestion des factures en cours...</p>
                            </div>
                        )}

                        {ongletActive === "messages" && (
                            <div className="messages-section">
                                <h2>Boîte de Messagerie</h2>
                                <p>Interactions avec les clients en cours...</p>
                            </div>
                        )}
                        {ongletActive === "services" && (
                            <GestionServices/>
                        )}
                    </section>
                </div>
            )}
        </main>
    );
}

export default EspaceAdmin;
