const initialState = {
    services:[],
    loading:false,
    error: null,
}

export const serviceReducer = (state = initialState, action) => {
    switch(action.type){
        case "FETCH_SERVICES_REQUEST":
            return { ...state, loading: true, error: null };
        case "FETCH_SERVICES_SUCCESS":
            return { ...state, services: action.payload, loading: false, error: null };
        case "FETCH_SERVICES_FAILURE":
            return { ...state, loading: false, error: action.payload };
        case "ADD_SERVICE_REQUEST":
            return { ...state, loading: true, error: null };
        case "ADD_SERVICE_SUCCESS":
            return { ...state, loading: false, error: null };
        case "ADD_SERVICE_FAILURE":
            return { ...state, loading: false, error: action.payload };
            
        default:
            return state;

        }

}