const initialState = {
    loading:false,
    error: null,
}

export const souscriptionReducer = (state = initialState, action) => {
    switch(action.type){
        case "ADD_SOUSCRIPTION_REQUEST":
            return { ...state, loading: true, error: null };
        case "ADD_SERVICE_SUCCESS":
            return { ...state, loading: false, error: null };
        case "ADD_SERVICE_FAILURE":
            return { ...state, loading: false, error: action.payload };
            
        default:
            return state;
    }
}