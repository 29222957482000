import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addRappel,clearRappelAdded } from "../../redux/actions/rappelActions";

const GestionRappel = ({ onClose }) => {
    const { selectedProspect } = useSelector((state) => state.prospect);
    const { rappelAdded, loadingAddRappel, errorAddRappel } = useSelector((state) => state.rappel);

    const dispatch = useDispatch();

    const [formDataRappel, setFormDataRappel] = useState({
        idProspect: selectedProspect ? selectedProspect.idProspect : null,
        nomContactProspect: "",
        bestTimeRappel: "",
        raisonRappel: "",
    });

    // Met à jour idProspect si selectedProspect change
    useEffect(() => {
        setFormDataRappel((prev) => ({
            ...prev,
            idProspect: selectedProspect ? selectedProspect.idProspect : null,
        }));
    }, [selectedProspect]);

    // Ferme la modal si rappel ajouté avec succès
    useEffect(() => {
        if (rappelAdded && !errorAddRappel) {
            dispatch(clearRappelAdded());
            onClose();
        }
    }, [rappelAdded, errorAddRappel, onClose,dispatch]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormDataRappel((prev) => ({ ...prev, [name]: value }));
    };

    const handleSubmitRappel = (e) => {
        e.preventDefault();
        const rappel = {
            nomContactProspect: formDataRappel.nomContactProspect,
            bestTimeRappel: formDataRappel.bestTimeRappel,
            idProspect:selectedProspect.idProspect,
            raisonDuRappel:formDataRappel.raisonRappel,
        }
        dispatch(addRappel(rappel));
    };

    return (
        <div>
            <div className="input-form">
                <label htmlFor="nomContactProspect">Personne à rappeler</label>
                <input
                    type="text"
                    name="nomContactProspect"
                    id="nomContactProspect"
                    value={formDataRappel.nomContactProspect}
                    onChange={handleChange}
                />
            </div>
            <div className="input-form">
                <label htmlFor="bestTimeRappel">Meilleurs moments pour rappeler</label>
                <input
                    type="text"
                    name="bestTimeRappel"
                    id="bestTimeRappel"
                    value={formDataRappel.bestTimeRappel}
                    onChange={handleChange}
                />
            </div>
            <div className="input-form">
                <label htmlFor="raisonRappel">Raisons du rappel</label>
                <input
                    type="text"
                    name="raisonRappel"
                    id="raisonRappel"
                    value={formDataRappel.raisonRappel}
                    onChange={handleChange}
                />
            </div>
            <button className="btn-grad" onClick={handleSubmitRappel}>
                {loadingAddRappel ? "ENREGISTREMENT EN COURS" : "ENREGISTRER LE RAPPEL"}
            </button>
            <button className="btn-grad" onClick={onClose}>ANNULER</button>

            {errorAddRappel && (
                <div>Enregistrement du rappel impossible : {errorAddRappel}</div>
            )}
        </div>
    );
};

export default GestionRappel;
