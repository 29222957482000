const BASE_URL = "/api/clients";
export const ClientsApi = {
    fetchClients: async (filters) => {
     
        const queryString = new URLSearchParams(filters).toString();
        const response = await fetch(`${BASE_URL}?${queryString}`);
        if (!response.ok) {
            throw new Error("Erreur lors de la récupération des clients.");
        }
        return response.json();
    },
}