import { ServicesApi } from "../../services/ServicesApi";
// Action types
export const FETCH_SERVICES_REQUEST = "FETCH_SERVICES_REQUEST";
export const FETCH_SERVICES_SUCCESS = "FETCH_SERVICES_SUCCESS";
export const FETCH_SERVICES_FAILURE = "FETCH_SERVICES_FAILURE";

export const ADD_SERVICE_REQUEST = "ADD_SERVICE_REQUEST";
export const ADD_SERVICE_SUCCESS = "ADD_SERVICE_SUCCESS";
export const ADD_SERVICE_FAILURE = "ADD_SERVICE_FAILURE";

export const DELETE_SERVICE_REQUEST = "DELETE_SERVICE_REQUEST";
export const DELETE_SERVICE_SUCCESS = "DELETE_SERVICE_SUCCESS";
export const DELETE_SERVICE_FAILURE = "DELETE_SERVICE_FAILURE";


// Action creators

export const fetchServices = () => async (dispatch) => {
    dispatch(fetchServicesRequest());
    try {
        // Récupérer les services
        const services = await ServicesApi.getAllServices();

        

        // Dispatch de la réponse finale avec les services et leurs fichiers associés
        dispatch(fetchServicesSuccess(services.data));
    } catch (error) {
        dispatch(fetchServicesFailure(error.message));
    }
};

export const fetchServicesRequest = () => ({
    type: FETCH_SERVICES_REQUEST,
});

export const fetchServicesSuccess = (services) => ({
    type: FETCH_SERVICES_SUCCESS,
    payload: services,
});

export const fetchServicesFailure = (error) => ({
    type: FETCH_SERVICES_FAILURE,
    payload: error,
});

export const addService = (service) => async (dispatch) => {
    dispatch(addServiceRequest());
    try {
        await ServicesApi.addService(service);
        dispatch(addServiceSuccess());
        // Optionnel : rafraîchir la liste des services après ajout
        dispatch(fetchServices());
    } catch (error) {
        dispatch(addServiceFailure(error.message));
    }
};
export const addServiceRequest = () => ({
    type: ADD_SERVICE_REQUEST,
});

export const addServiceSuccess = () => ({
    type: ADD_SERVICE_SUCCESS,
});

export const addServiceFailure = (error) => ({
    type: ADD_SERVICE_FAILURE,
    payload: error,
});

export const deleteServiceRequest = () => ({ type: DELETE_SERVICE_REQUEST });
export const deleteServiceSuccess = (data) => ({ type: DELETE_SERVICE_SUCCESS, payload: data });
export const deleteServiceFailure = (error) => ({ type: DELETE_SERVICE_FAILURE, payload: error });

export const deleteService = (idService) => async (dispatch) => {
    dispatch(deleteServiceRequest());
    try {
        const response = await ServicesApi.deleteService(idService);
        dispatch(deleteServiceSuccess(response));
        dispatch(fetchServices());
    } catch (error) {
        dispatch(deleteServiceFailure(error.message));
    }
};