import { useDispatch,useSelector } from "react-redux"
import "./style.css"
import { fetchStatsAppels } from "../../redux/actions/statsActions"
import { useEffect, useState } from "react"
const InfosAppelsEffectues = () => {
    const dispatch = useDispatch()
    const [selectedOption,setSelectedOption]=useState("jour");
    const {statsAppelsJour,statsAppelsMois,statsAppelsSemaine,statsAppelsAnnee,loading,error} = useSelector((state) => state.statsReducer);
    useEffect(() => {
        const today = new Date().toISOString().split('T')[0]; // Format YYYY-MM-DD
        dispatch(fetchStatsAppels(today));
    }, [dispatch]); // Ajout de dispatch dans les dépendances pour éviter les avertissements React

    const formatTime = (seconds) => {
        if (!seconds || isNaN(seconds)) return "00:00:00";
        
        const h = Math.floor(seconds / 3600);
        const m = Math.floor((seconds % 3600) / 60);
        const s = seconds % 60;

        return [h, m, s]
            .map(unit => String(unit).padStart(2, "0")) // Ajoute un zéro devant si nécessaire
            .join(":");
    };
    const handleChangeSelectedOption =(e)=>{
        setSelectedOption(e.target.value)
    }
    return(
        <div className="container-infos-appels">
            <select name="periodeAppel" id="periodeAppel" value={selectedOption} onChange={handleChangeSelectedOption}>
                <option value="jour">APPELS DU JOUR</option>
                <option value="semaine">APPELS DE LA SEMAINE</option>
                <option value="mois">APPELS DU MOIS</option>
                <option value="annee">APPELS DE L'ANNEE</option>

            </select>
            {
                statsAppelsJour&&!loading&&!error&&selectedOption==="jour"&&(
                    <>
                    <h3>Nombre d’appels effectués :</h3>
                    <p>{statsAppelsJour.nbTotalAppels}</p>
                    <h3>Temps d'appels effectués :</h3>
                    <p>{formatTime(statsAppelsJour.dureeTotalAppels)}</p>
                    <h3>Durée d’appel moyen :</h3>
                    <p>{formatTime(statsAppelsJour.dureeMoyenneAppels)}</p>
                    <h3>Nombre de rendez-vous obtenus :</h3>
                    <p>{statsAppelsJour.nbRdvObtenus}</p>
                    
                    
                </>
                )    
            }
            {
                statsAppelsSemaine&&!loading&&!error&&selectedOption==="semaine"&&(
                <>
                    <h3>Nombre d’appels effectués :</h3>
                    <p>{statsAppelsSemaine.nbTotalAppels}</p>
                    <h3>Temps d'appels effectués :</h3>
                    <p>{formatTime(statsAppelsSemaine.dureeTotalAppels)}</p>
                    <h3>Durée d’appel moyen :</h3>
                    <p>{formatTime(statsAppelsSemaine.dureeMoyenneAppels)}</p>
                    <h3>Nombre de rendez-vous obtenus :</h3>
                    <p>{statsAppelsSemaine.nbRdvObtenus}</p>
                </>
                )    
            }
            {
                statsAppelsMois&&!loading&&!error&&selectedOption==="mois"&&(
                <>
                   
                    <h3>Nombre d’appels effectués :</h3>
                    <p>{statsAppelsMois.nbTotalAppels}</p>
                    <h3>Temps d'appels effectués :</h3>
                    <p>{formatTime(statsAppelsMois.dureeTotalAppels)}</p>
                    <h3>Durée d’appel moyen :</h3>
                    <p>{formatTime(statsAppelsMois.dureeMoyenneAppels)}</p>
                    <h3>Nombre de rendez-vous obtenus :</h3>
                    <p>{statsAppelsMois.nbRdvObtenus}</p>
                </>
                )    
            }
            {
                statsAppelsAnnee&&!loading&&!error&&selectedOption==="annee"&&(
                <>
                   
                    <h3>Nombre d’appels effectués :</h3>
                    <p>{statsAppelsAnnee.nbTotalAppels}</p>
                    <h3>Temps d'appels effectués :</h3>
                    <p>{formatTime(statsAppelsAnnee.dureeTotalAppels)}</p>
                    <h3>Durée d’appel moyen :</h3>
                    <p>{formatTime(statsAppelsAnnee.dureeMoyenneAppels)}</p>
                    <h3>Nombre de rendez-vous obtenus :</h3>
                    <p>{statsAppelsAnnee.nbRdvObtenus}</p>
                </>
                )    
            }

        </div>
       
        
    )
    
}
export default InfosAppelsEffectues