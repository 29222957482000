import React from 'react'
import {createRoot} from 'react-dom/client'
import { BrowserRouter as Router, Routes, Route } from "react-router-dom"
import Header from './components/Header'
import Footer from './components/Footer'
import Home from './pages/Home'
import './index.css'
import { AuthProvider } from './context/AuthContext'
import HomeSalonCoiffure1 from './sites/salonsCoiffures/demo1/pages/HomeSalonCoiffure1'
import SalonCoiffureLayout from './sites/salonsCoiffures/demo1/components/SalonCoiffure1Layout'
import 'bootstrap/dist/js/bootstrap.bundle.min';
// import EspaceClient from './pages/EspaceClient'
import EspaceAdmin from './pages/EspaceAdmin'
import Contact from './pages/Contact'
import Jeux from './pages/Jeux'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { store, persistor }  from './redux/store.jsx'
import PageRendezVous from './pages/PageRendezVous/index.jsx'
import CancelRendezVous from './pages/CancelRendezVous/index.jsx'
import MusicScoreViewer from './pages/MusicScoreViewer/index.jsx'
import ListeScores from './pages/ListeScores/index.jsx'


const container = document.getElementById('root')
const root = createRoot(container)
root.render(
  <React.StrictMode>
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
   
    <AuthProvider>
    <Router>
      <Routes>
        <Route path="/" element={
          <div className="container-fluid">
            <Header />
            <Home />
            <Footer />
          </div>
        } />
        
          <Route path="/contact/:preselected?" element={
          <div className="container-fluid">
            <Header />
            <Contact />
            <Footer />
          </div>
          } />
          <Route path="/rendezVous/:uuid?" element={
          <div className="container-fluid">
            <Header />
            <PageRendezVous />
            <Footer />
          </div>
          } />
          <Route path='/cancelRendezVous/:token' element={
            <div>
              <Header />
              <CancelRendezVous />
              <Footer />
            </div>
          }/>

          <Route path='/listeScores' element={
            <div className='container-fluid'>
              <Header />
              <ListeScores/>
              <Footer />
            </div>
          }/>
          <Route path='/viewScore/:scoreId' element={
            <div className="container-fluid">
            <Header />
            <MusicScoreViewer/>
            <Footer />
          </div>
          }/>


        {/* <Route path="/espace_client" element={
          <div className="container-fluid">
            <Header />
            <EspaceClient />
            <Footer />
          </div>
        } /> */}
        <Route path="/espace_admin" element={
          <div className="container-fluid">
            <Header />
            <EspaceAdmin />
            <Footer />
          </div>
        } />
        
        <Route path="/demos" element={
          <div className="container-fluid">
            <Header />
            <Jeux/>
            <Footer />
          </div>
        } />
        

        {/*site demo coiffure1 */}
        <Route path="/demo/coiffure1" element={<SalonCoiffureLayout />}>
          <Route path="/demo/coiffure1/" element={<HomeSalonCoiffure1 />} />
          <Route path="/demo/coiffure1/services" element={<HomeSalonCoiffure1 />} />
          <Route path="/demo/coiffure1/produits" element={<HomeSalonCoiffure1 />} />
          <Route path="/demo/coiffure1/galerie" element={<HomeSalonCoiffure1 />} />
          <Route path="/demo/coiffure1/avis" element={<HomeSalonCoiffure1 />} />
          <Route path="/demo/coiffure1/contact" element={<HomeSalonCoiffure1 />} />
          <Route path="/demo/coiffure1/booking" element={<HomeSalonCoiffure1 />} />
        </Route>
      </Routes>
    </Router>
    </AuthProvider>
    </PersistGate>
    </Provider>
  </React.StrictMode>
)

