import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchTypeProspects, updateProspect } from "../../redux/actions/prospectActions";

const GestionProspect = ({ onClose }) => {
    const dispatch = useDispatch();

    const { selectedProspect, typesProspects, loadingTypes, errorTypes } = useSelector((state) => state.prospect);

    const [formData, setFormData] = useState(selectedProspect || {});

    useEffect(() => {
        if (selectedProspect) {
            setFormData(selectedProspect);
        }
    }, [selectedProspect]);

    useEffect(() => {
        dispatch(fetchTypeProspects());
    }, [dispatch]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleUpdate = () => {
        dispatch(updateProspect(formData));
        onClose();
    };

    return (
        selectedProspect && (
            <div>
                <div className="input-form">
                    <label htmlFor="raisonSocialeProspect">RAISON SOCIALE</label>
                    <input
                        type="text"
                        name="raisonSocialeProspect"
                        id="raisonSocialeProspect"
                        value={formData.raisonSocialeProspect || ""}
                        onChange={handleInputChange}
                    />
                </div>

                {!loadingTypes && !errorTypes && typesProspects && (
                    <>
                        <label htmlFor="typeEntrepriseProspect">TYPE PROSPECT</label>
                        <div className="input-form">
                            <select
                                name="typeEntrepriseProspect"
                                id="typeEntrepriseProspect"
                                value={formData.typeEntrepriseProspect || ""}
                                onChange={handleInputChange}
                            >
                                <option value="">Sélectionnez un type</option> {/* Option par défaut */}
                                {typesProspects.map((type) => (
                                    <option key={type} value={type}>
                                        {type}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </>
                )}

                <div className="input-form">
                    <label htmlFor="adresseProspect">ADRESSE</label>
                    <input
                        type="text"
                        name="adresseProspect"
                        id="adresseProspect"
                        value={formData.adresseProspect || ""}
                        onChange={handleInputChange}
                    />
                </div>

                <div className="input-form">
                    <label htmlFor="codePostalProspect">CODE POSTAL</label>
                    <input
                        type="text"
                        name="codePostalProspect"
                        id="codePostalProspect"
                        value={formData.codePostalProspect || ""}
                        onChange={handleInputChange}
                    />
                </div>

                <div className="input-form">
                    <label htmlFor="villeProspect">VILLE</label>
                    <input
                        type="text"
                        name="villeProspect"
                        id="villeProspect"
                        value={formData.villeProspect || ""}
                        onChange={handleInputChange}
                    />
                </div>

                <button className="btn-grad" onClick={handleUpdate}>METTRE À JOUR</button>
                <button className="btn-grad" onClick={onClose}>ANNULER</button>
            </div>
        )
    );
};

export default GestionProspect;
