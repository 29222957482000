import React, { useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { resetMessage, sendMessage } from '../../redux/actions/contactActions';
import "./formContact.css";

function Contact() {
    const { messageEnvoye, loading,error } = useSelector((state) => state.contactReducer);
    const dispatch = useDispatch();

    const initialData = {
        nom: '',
        prenom: '',
        mail: '',
        tel: '',
        message: '',
    };

    const [formData, setFormData] = useState(initialData);
    const [errors, setErrors] = useState({});

    const validateForm = () => {
        let newErrors = {};

        // Validation email
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(formData.mail)) {
            newErrors.mail = "Adresse email invalide";
        }

        // Validation téléphone (10 à 15 chiffres)
        const phoneRegex = /^[0-9]{10,15}$/;
        if (!phoneRegex.test(formData.tel)) {
            newErrors.tel = "Numéro de téléphone invalide (10 à 15 chiffres)";
        }

        if(formData.message.length<10){
            newErrors.message = "Le message ne peut être vide ou inferieur à 10 caractères"
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
        setErrors({ ...errors, [name]: "" }); // Efface l'erreur une fois corrigée
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (validateForm()) {
            const data = new FormData();
            data.append("nom", formData.nom);
            data.append("prenom", formData.prenom);
            data.append("mail", formData.mail);
            data.append("tel", formData.tel);
            data.append("message", formData.message);
            data.append("typeEmail","mailForm");
            dispatch(sendMessage(data));
        }
    };

    return (
        <main>
            {messageEnvoye === null && !error && (
                <div className="formContact">
                    <h1>FORMULAIRE DE CONTACT</h1>

                    <label htmlFor="nom">Nom :</label>
                    <input
                        type="text"
                        id="nom"
                        name="nom"
                        value={formData.nom}
                        onChange={handleInputChange}
                        required
                    />

                    <label htmlFor="prenom">Prénom :</label>
                    <input
                        type="text"
                        id="prenom"
                        name="prenom"
                        value={formData.prenom}
                        onChange={handleInputChange}
                        required
                    />

                    <label htmlFor="mail">Email :</label>
                    <input
                        type="email"
                        id="mail"
                        name="mail"
                        value={formData.mail}
                        onChange={handleInputChange}
                        required
                    />
                    {errors.mail && <span className="error">{errors.mail}</span>}

                    <label htmlFor="tel">Téléphone :</label>
                    <input
                        type="tel"
                        id="tel"
                        name="tel"
                        value={formData.tel}
                        onChange={handleInputChange}
                    />
                    {errors.tel && <span className="error">{errors.tel}</span>}

                    <label htmlFor="message">Message / Description du projet :</label>
                    <textarea
                        id="message"
                        name="message"
                        value={formData.message}
                        onChange={handleInputChange}
                        rows="5"
                        placeholder="Décrivez votre projet ou votre demande"
                    />
                    {errors.message && <span className="error">{errors.message}</span>}
                    <button
                        className="btn-grad"
                        onClick={handleSubmit}
                        disabled={loading}
                    >
                        {loading ? 'Envoi en cours...' : 'ENVOYER'}
                    </button>
                </div>
            )}

            {messageEnvoye!==null && !error && (
                <section className="messageConfirmation">
                    <p>Je vous confirme que votre message a bien été envoyé, je vous apporterai une réponse dans les plus brefs délais.</p>
                    <button className="btn-grad" onClick={() => {
                        dispatch(resetMessage());
                        setFormData(initialData);
                        setErrors({});
                    }}>
                        OK
                    </button>
                </section>
            )}
            {error && (
                <section className="messageConfirmation">
                    <p>Erreur lors de l'envoi: {error}. Veuillez réessayer ultérieurement</p>
                    <button className="btn-grad" onClick={() => {
                        dispatch(resetMessage());
                        setFormData(initialData);
                        setErrors({});
                    }}>
                        OK
                    </button>
                </section>
            )}
        </main>
    );
}

export default Contact;
