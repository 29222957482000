import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router';
import GestionMusicXmlScore from '../../components/GestionMusicXmlScore';

const ListeScores = () => {
  const navigate = useNavigate();
  const [showForm, setShowForm] = useState(false);
  const [scores, setScores] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

    
        const fetchScores = async () => {
            try {
              const response = await fetch('/api/musicXml/list'); // Remplacez par l'URL correcte de votre API
              if (!response.ok) {
                throw new Error('Erreur lors de la récupération des partitions.');
              }
              const data = await response.json();
              setScores(data.data);
            } catch (err) {
              setError(err.message);
            } finally {
              setLoading(false);
            }
          };
      
    
  // Fetch des partitions au montage du composant
  useEffect(() => {
    fetchScores()
  }, []);
  const onAddingScoreSuccess = ()=>{
    fetchScores();
    setShowForm(false)
  }
  return (
    <main style={styles.main}>
      <section>
        {/* Formulaire pour ajouter une partition */}
        {showForm && <GestionMusicXmlScore onSuccess={onAddingScoreSuccess} />}
        {!showForm && (
          <button style={styles.addButton} onClick={() => setShowForm(true)}>
            AJOUTER UNE PARTITION
          </button>
        )}

        {/* Affichage du chargement ou des erreurs */}
        {loading && <p>Chargement des partitions...</p>}
        {error && <p style={{ color: 'red' }}>Erreur : {error}</p>}

        {/* Affichage des partitions sous forme de cartes */}
        {!loading && !error && (
          <div style={styles.cardContainer}>
            {scores.length > 0 ? (
              scores.map((score) => (
                <div key={score.idMusicXmlScore} style={styles.card}>
                  <h3>{score.titreMusicXmlScore}</h3>
                  <p><strong>Compositeur :</strong> {score.composerMusicXmlScore}</p>
                  <p><strong>Instrumentation :</strong> {score.instrumentationMusicXmlScore}</p>
                  <button style={styles.viewButton} onClick={() => navigate(`/viewScore/${score.idMusicXmlScore}`)}>
                    Voir la partition
                  </button>
                </div>
              ))
            ) : (
              <p>Aucune partition disponible</p>
            )}
          </div>
        )}
      </section>
    </main>
  );
};

// Styles CSS en objet JS
const styles = {
  main: {
    padding: '20px',
    textAlign: 'center',
  },
  addButton: {
    padding: '10px 20px',
    fontSize: '16px',
    backgroundColor: '#007bff',
    color: 'white',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    marginBottom: '20px',
  },
  cardContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))',
    gap: '20px',
    justifyContent: 'center',
    marginTop: '20px',
  },
  card: {
    backgroundColor: '#f8f9fa',
    color:"black",
    padding: '20px',
    borderRadius: '10px',
    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
    textAlign: 'center',
  },
  viewButton: {
    marginTop: '10px',
    padding: '10px',
    backgroundColor: '#28a745',
    color: 'white',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
  },
};

export default ListeScores;
