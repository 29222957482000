import { ProspectApi } from "../../services/ProspectApi.js";

// Action Types
export const FETCH_PROSPECTS_REQUEST = "FETCH_PROSPECTS_REQUEST";
export const FETCH_PROSPECTS_SUCCESS = "FETCH_PROSPECTS_SUCCESS";
export const FETCH_PROSPECTS_FAILURE = "FETCH_PROSPECTS_FAILURE";
export const SET_SELECTED_PROSPECT = "SET_SELECTED_PROSPECT";
export const CLEAR_SELECTED_PROSPECT = "CLEAR_SELECTED_PROSPECT";
export const FETCH_TYPES_PROSPECTS_REQUEST ="FETCH_TYPES_PROSPECTS_REQUEST";
export const FETCH_TYPES_PROSPECTS_SUCCESS ="FETCH_TYPES_PROSPECTS_SUCCESS";
export const FETCH_TYPES_PROSPECTS_FAILURE ="FETCH_TYPES_PROSPECTS_FAILURE";
export const FETCH_VILLES_PROSPECTS_REQUEST ="FETCH_VILLES_PROSPECTS_REQUEST";
export const FETCH_VILLES_PROSPECTS_SUCCESS ="FETCH_VILLES_PROSPECTS_SUCCESS";
export const FETCH_VILLES_PROSPECTS_FAILURE ="FETCH_VILLES_PROSPECTS_FAILURE";
export const UPDATE_PROSPECT_REQUEST = "UPDATE_PROSPECT_REQUEST";
export const UPDATE_PROSPECT_SUCCESS = "UPDATE_PROSPECT_SUCCESS";
export const UPDATE_PROSPECT_FAILURE = "UPDATE_PROSPECT_FAILURE";




// Action Creators
export const fetchProspectsRequest = () => ({
    type: FETCH_PROSPECTS_REQUEST,
});

export const fetchProspectsSuccess = (data) => ({
    type: FETCH_PROSPECTS_SUCCESS,
    payload: data,
});

export const fetchProspectsFailure = (error) => ({
    type: FETCH_PROSPECTS_FAILURE,
    payload: error,
});

export const fetchTypesProspectsRequest = () => ({
    type: FETCH_TYPES_PROSPECTS_REQUEST,
});

export const fetchTypesProspectsSuccess = (data) => ({
    type: FETCH_TYPES_PROSPECTS_SUCCESS,
    payload: data,
});

export const fetchVillesProspectsFailure = (error) => ({
    type: FETCH_VILLES_PROSPECTS_FAILURE,
    payload: error,
});

export const fetchVillesProspectsRequest = () => ({
    type: FETCH_VILLES_PROSPECTS_REQUEST,
});

export const fetchVillesProspectsSuccess = (data) => ({
    type: FETCH_VILLES_PROSPECTS_SUCCESS,
    payload: data,
});

export const fetchTypesProspectsFailure = (error) => ({
    type: FETCH_TYPES_PROSPECTS_FAILURE,
    payload: error,
});

export const updateProspect = (prospect)=> async (dispatch) => {
    dispatch(updateProspectRequest());
    try {
        const response = await ProspectApi.updateProspect(prospect);
        dispatch(updateProspectSuccess(response));
    } catch (error) {
        dispatch(updateProspectFailure(error.message));
    }
}

export const updateProspectRequest = () => ({
    type: UPDATE_PROSPECT_REQUEST,
    
})

export const updateProspectSuccess = (data) => ({
    type: UPDATE_PROSPECT_SUCCESS,
    payload: data
})

export const updateProspectFailure = (error) => ({
    type: UPDATE_PROSPECT_FAILURE,
    payload:error
})

export const setProspectQualifie = (prospect)=> async (dispatch) => {
    dispatch(setProspectQualifieRequest());
    try {
        const response = await ProspectApi.setProspectQualifie(prospect);
        dispatch(setProspectQualifieSuccess(response));
    } catch (error) {
        dispatch(setProspectQualifieFailure(error.message));
    }
}

export const setProspectQualifieRequest = () => ({
    type: "SET_PROSPECT_QUALIFIE_REQUEST",
})
export const setProspectQualifieSuccess = (data) => ({
    type: "SET_PROSPECT_QUALIFIE_SUCCESS",
    payload: data
})
export const setProspectQualifieFailure = (error) => ({
    type: "SET_PROSPECT_QUALIFIE_FAILURE",
    payload:error
})

export const setSelectedProspect = (prospect) => ({
    type: SET_SELECTED_PROSPECT,
    payload: prospect,
});

export const clearSelectedProspect = () => ({
    type: CLEAR_SELECTED_PROSPECT,
});

// Thunks
export const fetchProspects = (filters) => async (dispatch) => {
    dispatch(fetchProspectsRequest());
    try {
        const response = await ProspectApi.fetchProspects(filters);
        dispatch(fetchProspectsSuccess({prospects:response}));
    } catch (error) {
        dispatch(fetchProspectsFailure(error.message));
    }
};

export const generateRandomProspect = (filters) => async (dispatch) => {
    dispatch(fetchProspectsRequest());
    try {
        const prospect = await ProspectApi.generateRandomProspect(filters);
        dispatch(setSelectedProspect(prospect));
    } catch (error) {
        dispatch(fetchProspectsFailure(error.message));
    }
};

export const fetchTypeProspects = () => async (dispatch) => {
    dispatch(fetchTypesProspectsRequest());
    try {
        const response = await ProspectApi.fetchTypesProspects();
        dispatch(fetchTypesProspectsSuccess({typesProspects:response}));
    } catch (error) {
        dispatch(fetchTypesProspectsFailure(error.message));
    }
};

export const fetchVillesProspects = (filters) => async (dispatch) => {
    dispatch(fetchVillesProspectsRequest());
    try {
        const response = await ProspectApi.fetchVillesProspects(filters);
        dispatch(fetchVillesProspectsSuccess({villesProspects:response}));
    } catch (error) {
        dispatch(fetchVillesProspectsFailure(error.message));
    }
};
