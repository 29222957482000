import { ContactApi } from "../../services/ContactApi";

export const sendMessage = (message) => async (dispatch) => {
    dispatch(sendMessageRequest());
    try {
        await ContactApi.sendMessage(message);
        dispatch(sendMessageSuccess());
    } catch (error) {
        console.error("Erreur lors de l'envoi du message :", error.message);
        dispatch(sendMessageFailure(error.message));
    }
};

export const sendMessageRequest = () => ({
    type: "SEND_MESSAGE_REQUEST",
});

export const sendMessageSuccess = () => ({
    type: "SEND_MESSAGE_SUCCESS",
});

export const sendMessageFailure = (error) => ({
    type: "SEND_MESSAGE_FAILURE",
    payload: error,
});

export const resetMessage =()=>({
    type:"RESET_MESSAGE"
});
