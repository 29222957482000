import { useEffect } from 'react';
import { useAuth } from '../../context/AuthContext';

function useSessionCheck(intervalMs = 300000) {
    const { logout } = useAuth();

    useEffect(() => {
        const checkSession = async () => {
            try {
                const response = await fetch('/api/user/checkSession', {
                    method: 'GET',
                });

                if (!response.ok) {
                    if (response.status === 401) {
                        logout();
                    } else {
                    }
                    return;
                }

                const result = await response.json();
                if (result?.status === 'inactive') {
                    logout();
                }
            } catch (error) {
            }
        };

        checkSession(); // Appeler immédiatement à l'initialisation
        const interval = setInterval(checkSession, intervalMs);

        return () => clearInterval(interval); // Nettoyage de l'intervalle
    }, [logout, intervalMs]);
}

export default useSessionCheck;
