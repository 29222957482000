import React, { useState, useEffect } from 'react';
import { Link, NavLink } from 'react-router-dom';
import imgLogo from "./assets/logo5.png";
import './header.css';
import { useAuth } from '../../context/AuthContext';
import useSessionCheck from '../UseSessionCheck';

const Header = () => {
  const { userConnected, logout } = useAuth();
  useSessionCheck();

  const [isMobile, setIsMobile] = useState(window.innerWidth < 992);
  const [menuOpen, setMenuOpen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 992);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <header id="header">
      {/* Logo */}
      <div className='logo-container'>
        <Link className="navbarBrand" to="/">
          <img id="logoNavBar" src={imgLogo} alt="Logo" />
        </Link>
       {
        isMobile&&(
          <button
            className={`navbar-toggler-custom custom-toggler ${menuOpen ? 'menu-open' : ''}`}
            id="nav-toggle-button"
            onClick={toggleMenu}
            aria-label="Toggle navigation"
          >
            <span className="bar"></span>
            <span className="bar"></span>
            <span className="bar"></span>
          </button>
        )
       }
        

         
      </div>
      

      {/* Navbar */}
        
        <nav className={` ${isMobile && !menuOpen ? 'close' : 'navbar-header'}`}>
          <ul>
            {/* Accueil */}
            <li className="nav-item">
              <NavLink className="nav-link" to="/" onClick={() => setMenuOpen(false)}>
                ACCUEIL
              </NavLink>
            </li>

            {/* Contact */}
            <li className="nav-item">
              <NavLink className="nav-link" to="/contact" onClick={() => setMenuOpen(false)}>
                CONTACT
              </NavLink>
            </li>
            {/* Rendez vous */}
            <li className="nav-item">
              <NavLink className="nav-link" to="/rendezVous" onClick={() => setMenuOpen(false)}>
                RENDEZ-VOUS
              </NavLink>
            </li>

            {/* Espace Client */}
            {/* <li className="nav-item">
              <NavLink className="nav-link" to="/espace_client" onClick={() => setMenuOpen(false)}>
                ESPACE CLIENT
              </NavLink>
            </li> */}

            {userConnected && (
              <li className="nav-item">
                <div className="userConnected">
                  <span style={{ color: "white" }}>Bienvenue, {userConnected.mailUser}</span>
                  <input type="button" value="DÉCONNEXION" onClick={logout} />
                </div>
              </li>
            )}
          </ul>
      </nav>
          
      
    </header>
  );
};

export default Header;
