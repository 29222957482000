import { useParams } from "react-router-dom"; // Correction de l'import
import { cancelRendezVous, fetchRdvByUuid } from "../../redux/actions/rendezVousActions";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";

const CancelRendezVous = () => {
    const { token } = useParams();
    const dispatch = useDispatch();
    const { rdvByUuid, deleteRdvSuccess, errorDeleteRdv } = useSelector((state) => state.rendezVousReducer);

    useEffect(() => {
        if (token) {
            dispatch(fetchRdvByUuid(token)); // Correction de l'action appelée
        }
    }, [dispatch, token]);

    const handleCancel = () => {
        if (window.confirm("Êtes-vous sûr de vouloir annuler ce rendez-vous ?")) {
            dispatch(cancelRendezVous(token));
        }
    };

    return (
        <div>
            {rdvByUuid ? (
                <div>
                    <h2>Annulation du rendez-vous</h2>
                    <p><strong>Date :</strong> {rdvByUuid.rendezVous.dateRdv}</p>
                    <p><strong>Heure :</strong> {rdvByUuid.rendezVous.heureRdv}</p>
                    <p><strong>Prospect :</strong> {rdvByUuid.rendezVous.prenomProspect} {rdvByUuid.rendezVous.nomProspect}</p>
                    <button onClick={handleCancel}>Annuler le rendez-vous</button>
                </div>
            ) : deleteRdvSuccess ? (
                <p style={{ color: "green" }}>Le rendez-vous a été annulé avec succès.</p>
            ) : errorDeleteRdv ? (
                <p style={{ color: "red" }}>Erreur lors de l'annulation : {errorDeleteRdv}</p>
            ) : (
                <p>Chargement du rendez-vous...</p>
            )}
        </div>
    );
};

export default CancelRendezVous;
