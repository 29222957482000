const initialState = {
    listeRappels: [], // Liste des rappels existants
    rappelAdded: null,
    selectedRappel: null,
    loadingAddRappel: false,
    errorAddRappel: null,
    error:null,
    loading:false,
    
};

export const rappelReducer = (state = initialState, action) => {
    switch (action.type) {
        case "ADD_RAPPEL_REQUEST":
            return { ...state, loadingAddRappel: true, errorAddRappel: null };
        case "ADD_RAPPEL_SUCCESS":
            return { ...state, rappelAdded: action.payload.rappel, loadingAddRappel: false, errorAddRappel: null };
        case "ADD_RAPPEL_FAILURE":
            return { ...state, loadingAddRappel: false, errorAddRappel: action.payload };
        
        case "FETCH_RAPPELS_REQUEST":
            return { ...state, loading: true, error:null}
        case "FETCH_RAPPELS_SUCCESS":
            return { ...state, listeRappels: action.payload.listeRappels, loading: false, error: null }
        case "FETCH_RAPPELS_FAILURE":
            return { ...state, listeRappels: [], loading: false, error: action.payload }

        case "SET_SELECTED_RAPPEL":
            return {
                ...state,
                selectedRappel: action.payload,
                error: null,
            };
        case "CLEAR_SELECTED_RAPPEL":
            return {
                ...state,
                selectedRappel: null,
            };
        case "CLEAR_RAPPEL_ADDED":
            return {
                ...state,
                rappelAdded: null,
            };

        default:
            return state;
    }
};
