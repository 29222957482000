import { useEffect, useState } from "react"
import "./styles.css"
import { useDispatch, useSelector } from "react-redux";
import { fetchClients } from "../../redux/actions/clientsActions";
const GestionClients = ()=>{
    const dispatch = useDispatch();
    const { clients,loading, error } = useSelector((state) => state.clientsReducer);


    const [formData,setFormData] = useState(
        {
            filterNomClient:"",
            filterPrenomClient:"",
            filterRaisonSocialeClient:"",
            filterSiretClient:"",
            filterVilleClient:"",
            filterIsActiveClient:"",
        }
    )
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
        
    };
    const [page] = useState(1);
    const [limit] = useState(5);
    
    const handleSubmit = (e) => {
        const filters = {
            nomUser: formData.filterNomClient,
            prenomUser: formData.filterPrenomClient,
            raisonSocialeUser: formData.filterRaisonSocialeClient,
            siretUser: formData.filterSiretClient,
            villeUser: formData.filterVilleClient,
            isActiveUser: formData.filterIsActiveClient,
            limit:limit,
            page:page,

        };
        dispatch(fetchClients(filters))
    }
    useEffect(()=>{
        dispatch(fetchClients({limit:5,page:1}))
    },[dispatch])

    return (
        <div className="clients-section">
            <div className="form-filter-clients">
                <h2>RECHERCHER DES CLIENTS</h2>
                <div className="input-form">
                    <label htmlFor="filterNomClient">NOM</label>
                    <input type="text" id="filterNomClient" name="filterNomClient" value={formData.filterNomClient} onChange={handleInputChange} />
                </div>
                <div className="input-form">
                    <label htmlFor="filterPrenomClient">PRENOM</label>
                    <input type="text" id="filterPrenomClient" name="filterPrenomClient" value={formData.filterPrenomClient} onChange={handleInputChange} />
                </div>
                <div className="input-form">
                    <label htmlFor="filterRaisonSocialeClient">RAISON SOCIALE</label>
                    <input type="text" id="filterRaisonSocialeClient" name="filterRaisonSocialeClient" value={formData.filterRaisonSocialeClient} onChange={handleInputChange} />
                </div>
                <div className="input-form">
                    <label htmlFor="filterSiretClient">SIRET</label>
                    <input type="text" id="filterSiretClient" name="filterSiretClient" value={formData.filterSiretClient} onChange={handleInputChange} />
                </div>
                <div className="input-form">
                    <label htmlFor="filterVilleClient">VILLE</label>
                    <input type="text" id="filterVilleClient" name="filterVilleClient" value={formData.filterVilleClient} onChange={handleInputChange} />
                </div>
                <div className="input-form">
                    <label htmlFor="filterIsActiveClient">EST ACTIF ?</label>
                    <select id="filterIsActiveClient" name="filterIsActiveClient" value={formData.filterIsActiveClient} onChange={handleInputChange} >
                        <option value="">tous</option>
                        <option value="true">oui</option>
                        <option value="false">non</option>
                    </select>
                </div>
                <button onClick={()=>{handleSubmit()}}>FILTRER</button>
            </div>
            <div className="table-client-section">
                <h2>CLIENTS</h2>
                {
                    !loading&&!error&&clients&&clients.length>0&&(
                        <table>
                            <thead>
                                <tr>
                                    <th>Raison sociale</th>
                                    <th>Nom</th>
                                    <th>Prénom</th>
                                    <th>Siret</th>
                                    <th>Adresse</th>
                                    <th>Code postal</th>
                                    <th>Ville</th>
                                    <th>Est actif ?</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {   clients.map((client)=>(
                                        <tr key={client.idUser}>
                                            <td>{client.raisonSocialeUser}</td>
                                            <td>{client.nomUser}</td>
                                            <td>{client.prenomUser}</td>
                                            <td>{client.siretUser}</td>
                                            <td>{client.adresseUser}</td>
                                            <td>{client.codePostalUser}</td>
                                            <td>{client.villeUser}</td>
                                            <td>{client.isActiveUser?"oui":"non"}</td>
                                            <td></td>

                                        </tr>
                                ))
                                    
                                }
                            </tbody>
                        </table>
                    )
                }
                
            </div>
        </div>
    )
}
export default GestionClients