import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux";
import { fetchServices } from "../../redux/actions/serviceAction";
import { addSouscription } from "../../redux/actions/souscriptionActions";

const GestionSouscription = ({onClose})=>{
    const dispatch = useDispatch();

    const { services, loading, error } = useSelector((state) => state.serviceReducer);

    useEffect(() => {
        dispatch(fetchServices());
    }, [dispatch]);
    const selectedProspect = useSelector((state) => state.prospect.selectedProspect);

    const [formData, setFormData] = useState(
        {
            nomUser: "",
            prenomUser: "",
            mailUser: "",
            raisonSocialeUser: !selectedProspect? "":selectedProspect.raisonSocialeProspect,
            siretUser: "",
            telUser: !selectedProspect? "":selectedProspect.telephoneProspect,
            adresseUser: !selectedProspect? "":selectedProspect.adresseProspect,
            villeUser: !selectedProspect? "":selectedProspect.villeProspect,
            codePostalUser:!selectedProspect? "":selectedProspect.codePostalProspect,
            idService:""

        }
    )
        
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
        
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        const data = new FormData();
        data.append("nomClient", formData.nomUser);
        data.append("prenomClient", formData.prenomUser);
        data.append("mailClient", formData.mailUser);        
        data.append("raisonSocialeClient", formData.raisonSocialeUser);
        data.append("siretClient", formData.siretUser);
        data.append("telClient", formData.telUser);
        data.append("adresseClient", formData.adresseUser);
        data.append("villeClient", formData.villeUser);
        data.append("codePostalClient", formData.codePostalUser);
        data.append("idService", formData.idService);
        dispatch(addSouscription(data));


    }
    return (
        <div className="form-souscription">
            <div className="input-form">
                <label htmlFor="raisonSocialeUser">Raison sociale</label>
                <input type="text" id="raisonSocialeUser" name="raisonSocialeUser" value={formData.raisonSocialeUser} onChange={handleInputChange}/>
            </div>
            <div className="input-form">
                <label htmlFor="nomUser">Nom</label>
                <input type="text" id="nomUser" name="nomUser" value={formData.nomUser} onChange={handleInputChange}/>
            </div>
            <div className="input-form">
                <label htmlFor="prenomUser">Prénom</label>
                <input type="text" id="prenomUser" name="prenomUser" value={formData.prenomUser} onChange={handleInputChange}/>
            </div>
            <div className="input-form">
                <label htmlFor="mailUser">Email</label>
                <input type="mail" id="mailUser" name="mailUser" value={formData.mailUser} onChange={handleInputChange}/>
            </div>
            <div className="input-form">
                <label htmlFor="telUser">Téléphone</label>
                <input type="text" id="telUser" name="telUser" value={formData.telUser} onChange={handleInputChange}/>
            </div>
            <div className="input-form">
                <label htmlFor="adresseUser">Adresse</label>
                <input type="text" id="adresseUser" name="adresseUser" value={formData.adresseUser} onChange={handleInputChange}/>
            </div>
            <div className="input-form">
                <label htmlFor="villeUser">Ville</label>
                <input type="text" id="villeUser" name="villeUser" value={formData.villeUser} onChange={handleInputChange}/>
            </div>
            <div className="input-form">
                <label htmlFor="codePostalUser">Code postal</label>
                <input type="text" id="codePostalUser" name="codePostalUser" value={formData.codePostalUser} onChange={handleInputChange}/>
            </div>
            {
                services&&!loading&&!error&&(
                    <div className="input-form">
                        <label htmlFor="idService">Selectionner le service choisi</label>
                        <select id="idService" name="idService" value={formData.idService} onChange={handleInputChange}>
                            <option value="">Selectionner un service</option>
                            {
                                services.map((service)=>(
                                    <option key={service.idService} value={service.idService}>{service.nomService} - {service.tarifService} € {service.periodiciteService==="unique"?"":(service.periodiciteService==="mensuel"?" / mois":" / an")}</option>
                                ))
                            }
                        </select>
                    </div>
                )
            }
            
            <button onClick={handleSubmit}>ENREGISTRER</button>
            <button onClick={onClose}>ANNULER</button>
        </div>
    )
}
export default GestionSouscription