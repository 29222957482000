import { RappelApi } from "../../services/RappelApi";

export const addRappelRequest = ()=>({type: "ADD_RAPPEL_REQUEST"});
export const addRappelSuccess = (data) => ({ type: "ADD_RAPPEL_SUCCESS", payload: data });
export const addRappelFailure = (error) => ({ type: "ADD_RAPPEL_FAILURE", payload: error });

export const addRappel = (rappel) => async (dispatch) => {
    dispatch(addRappelRequest());
    try {
        const response = await RappelApi.addRappel(rappel);
        dispatch(addRappelSuccess(response));
        dispatch(fetchRappels());
    } catch (error) {
        dispatch(addRappelFailure(error.message));
    }
};

export const fetchRappelsRequest = () => ({type: "FETCH_RAPPELS_REQUEST"});
export const fetchRappelsSuccess = (data) => ({ type: "FETCH_RAPPELS_SUCCESS",payload: data });
export const fetchRappelsFailure = (error) => ({ type: "FETCH-RAPPELS_FAILURE", payload: error });

export const fetchRappels = () => async (dispatch) => {
    dispatch(fetchRappelsRequest)
    try{
        const response = await RappelApi.fetchRappelsAFaire();
        dispatch(fetchRappelsSuccess(response));
    } catch(error) {
        dispatch(fetchRappelsFailure(error.message));
    }
}

export const setSelectedRappel = (rappel) => ({type: "SET_SELECTED_RAPPEL",payload: rappel});

export const clearSelectedRappel = () => ({type: "CLEAR_SELECTED_RAPPEL"});
export const clearRappelAdded = () => ({type: "CLEAR_RAPPEL_ADDED"});
