const BASE_URL = "/api/contact";

export const ContactApi = {
    sendMessage: async (message) => {
        const response = await fetch(BASE_URL, {
            method: "POST",
            body: message, // FormData est directement envoyé ici
        });

        if (!response.ok) {
            // Essaye d'extraire le message d'erreur de la réponse JSON si possible
            let errorMessage = "Erreur lors de l'envoi du message";
            try {
                const errorData = await response.json();
                if (errorData?.message) {
                    errorMessage = errorData.message;
                }
            } catch (err) {
                console.error("Impossible de lire le message d'erreur JSON :", err);
            }
            
            throw new Error(errorMessage);
        }

        return await response.json();
    }
};
