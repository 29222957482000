const initialState = {
    statsAppels9: null,
    statsAppels10: null,
    statsAppels11: null,
    statsAppels14: null,
    statsAppels15: null,
    statsAppels16: null,
    statsAppelsJour: null,
    statsAppelsSemaine: null,
    statsAppelsMois: null,
    statsAppelsAnnee: null,
    loading: false,
    error: null,
};

// Fonction pour générer les cases pour chaque heure/statistique
const createStatsCases = (hour) => ({
    [`FETCH_STATS_APPELS_${hour}_REQUEST`]: (state) => ({ ...state, loading: true, error: null }),
    [`FETCH_STATS_APPELS_${hour}_SUCCESS`]: (state, action) => ({
        ...state,
        [`statsAppels${hour}`]: action.payload,
        loading: false,
        error: null,
    }),
    [`FETCH_STATS_APPELS_${hour}_FAILURE`]: (state, action) => ({
        ...state,
        loading: false,
        error: action.payload,
    }),
});

export const statsReducer = (state = initialState, action) => {
    const hours = [9, 10, 11, 14, 15, 16];
    const statsCases = {
        ...hours.reduce((acc, hour) => ({ ...acc, ...createStatsCases(hour) }), {}),
        'FETCH_STATS_APPELS_JOUR_REQUEST': (state) => ({ ...state, loading: true, error: null }),
        'FETCH_STATS_APPELS_JOUR_SUCCESS': (state, action) => ({
            ...state,
            statsAppelsJour: action.payload.data,
            loading: false,
            error: null,
        }),
        'FETCH_STATS_APPELS_JOUR_FAILURE': (state, action) => ({
            ...state,
            loading: false,
            error: action.payload,
        }),
        'FETCH_STATS_APPELS_SEMAINE_REQUEST': (state) => ({ ...state, loading: true, error: null }),
        'FETCH_STATS_APPELS_SEMAINE_SUCCESS': (state, action) => ({
            ...state,
            statsAppelsSemaine: action.payload.data,
            loading: false,
            error: null,
        }),
        'FETCH_STATS_APPELS_SEMAINE_FAILURE': (state, action) => ({
            ...state,
            loading: false,
            error: action.payload,
        }),
        'FETCH_STATS_APPELS_MOIS_REQUEST': (state) => ({ ...state, loading: true, error: null }),
        'FETCH_STATS_APPELS_MOIS_SUCCESS': (state, action) => ({
            ...state,
            statsAppelsMois: action.payload.data,
            loading: false,
            error: null,
        }),
        'FETCH_STATS_APPELS_MOIS_FAILURE': (state, action) => ({
            ...state,
            loading: false,
            error: action.payload,
        }),
        'FETCH_STATS_APPELS_ANNEE_REQUEST': (state) => ({ ...state, loading: true, error: null }),
        'FETCH_STATS_APPELS_ANNEE_SUCCESS': (state, action) => ({
            ...state,
            statsAppelsAnnee: action.payload.data,
            loading: false,
            error: null,
        }),
        'FETCH_STATS_APPELS_ANNEE_FAILURE': (state, action) => ({
            ...state,
            loading: false,
            error: action.payload,
        }),
    };

    // Si l'action existe dans statsCases, on l'exécute
    if (statsCases[action.type]) {
        return statsCases[action.type](state, action);
    }

    return state;
};

