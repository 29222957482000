import { useState } from "react";

const GestionMusicXmlScore = ({onSuccess}) => {
    // Initialisation de formData avec les données par défaut
    const [formData, setFormData] = useState({
        titre: "",
        composer: "",
        instrumentation: "",
        fileXml: null,
    });

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [successMessage, setSuccessMessage] = useState("");

    // Gère les changements dans les champs de texte
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    // Gère le changement de fichier pour MusicXML
    const handleFileChange = (e) => {
        const file = e.target.files[0];
        setFormData({
            ...formData,
            fileXml: file, // Stocke le fichier MusicXML
        });
    };

    // Soumission du formulaire
    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError(null);
        setSuccessMessage("");

        const data = new FormData();
        data.append("titreMusicXml", formData.titre);
        data.append("composerMusicXml", formData.composer);
        data.append("instrumentation", formData.instrumentation);
        data.append("musicXmlScore", formData.fileXml);

        try {
            // Appel API pour envoyer les données
            const response = await fetch("/api/musicXml/add", {
                method: "POST",
                body: data, // FormData est directement envoyé ici
            });

            if (!response.ok) {
                throw new Error("Erreur lors de l'ajout de la partition.");
            }

            const result = await response.json();
            console.log("Réponse API:", result);
            setSuccessMessage("Partition ajoutée avec succès !");
            setFormData({ titre: "", composer: "", instrumentation: "", fileXml: null });
            onSuccess()
        } catch (error) {
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div>
            <form onSubmit={handleSubmit}>
                <div className="input-form">
                    <label htmlFor="titre">Titre de l'œuvre</label>
                    <input 
                        type="text" 
                        name="titre" 
                        id="titre" 
                        value={formData.titre} 
                        onChange={handleInputChange} 
                        required
                    />
                </div>
                <div className="input-form">
                    <label htmlFor="composer">Compositeur de l'œuvre</label>
                    <input 
                        type="text" 
                        name="composer" 
                        id="composer" 
                        value={formData.composer} 
                        onChange={handleInputChange} 
                        required
                    />
                </div>
                <div className="input-form">
                    <label htmlFor="instrumentation">Instrument(s)</label>
                    <input 
                        type="text" 
                        name="instrumentation" 
                        id="instrumentation" 
                        value={formData.instrumentation} 
                        onChange={handleInputChange} 
                        required
                    />
                </div>
                <div className="input-form">
                    <label htmlFor="fileXml">Fichier MusicXML / MEI</label>
                    <input 
                        type="file" 
                        id="fileXml" 
                        name="fileXml" 
                        onChange={handleFileChange} 
                        required
                    />
                </div>

                {/* Affichage du message de chargement */}
                {loading && <p style={{ color: "blue" }}>Envoi en cours...</p>}
                
                {/* Affichage des erreurs */}
                {error && <p style={{ color: "red" }}>Erreur : {error}</p>}

                {/* Affichage du succès */}
                {successMessage && <p style={{ color: "green" }}>{successMessage}</p>}

                <button 
                    className="btn-grad" 
                    type="submit" 
                    disabled={loading}
                >
                    {loading ? "AJOUT EN COURS..." : "AJOUTER"}
                </button>
            </form>
        </div>
    );
};

export default GestionMusicXmlScore;
