const BASE_URL = "/api/prospection/appel";

export const AppelApi = {
    addAppel: async (appel) => {
        try {
            const response = await fetch(`${BASE_URL}/add`, {
                method: "POST",
                body: appel, // FormData est directement envoyé ici
            });

            if (!response.ok) {
                const errorMessage = `Erreur ${response.status}: ${response.statusText}`;
                throw new Error(errorMessage);
            }

            return await response.json();
        } catch (error) {
            console.error("Erreur API (addAppel) :", error.message);
            return { success: false, error: error.message };
        }
    },

    fetchAppels: async (filters = {}) => {
        try {
            const queryString = filters ? `?${new URLSearchParams(filters)}` : "";
            const response = await fetch(`${BASE_URL}${queryString}`);

            if (!response.ok) {
                const errorMessage = `Erreur ${response.status}: ${response.statusText}`;
                throw new Error(errorMessage);
            }

            return await response.json();
        } catch (error) {
            console.error("Erreur API (fetchAppels) :", error.message);
            return { success: false, error: error.message };
        }
    },
};
